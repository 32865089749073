<template>
  <v-row class="dashboardview ma-0 homeview p-relative min-height-100">
    <v-col cols="12">
      <v-row class="contentarea">
        <v-col
          cols="12"
          sm="4"
          lg="3"
          class="d-flex d-sm-block d-lg-flex flex-column pb-0 pb-lg-3"
        >
          <Greeting> </Greeting>

          <CEOCorner class="d-none d-sm-flex"></CEOCorner>
        </v-col>

        <v-col
          cols="12"
          sm="8"
          lg="6"
          class="px-lg-0 pl-sm-0 pt-0 pt-sm-3 d-flex flex-column"
        >
          <Slider></Slider>
          <CEOCorner class="mt-3 mt-lg-0 d-sm-none"></CEOCorner>
          <DepartmentInFocus class="mt-0 mt-sm-3 mt-lg-0 d-lg-none">
          </DepartmentInFocus>
          <v-row no-gutters class="my-0 my-lg-3 fill-height">
            <NewsAndUpdates class="d-sm-none d-lg-flex"></NewsAndUpdates>
            <!-- <StarClub class="d-sm-none d-lg-block"></StarClub> -->
          </v-row>
        </v-col>
        <v-col cols="12" class="d-none d-sm-flex pa-0 d-lg-none">
          <NewsAndUpdates></NewsAndUpdates>
        </v-col>
        <v-col cols="12" lg="3" class="d-flex flex-column">
          <DepartmentInFocus class="d-none d-lg-flex"> </DepartmentInFocus>

          <SpecialEvents> </SpecialEvents>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" lg="6" class="pt-0 pr-lg-1">
          <v-card>
            <v-card-title class="borderBottom-1 border-accentxlight"
              >Social Media
            </v-card-title>
            <v-card-text class="socialmediadiv">
              <v-row>
                <v-col cols="12" sm="6" class="fbdiv">
                  <iframe src="https://www.instagram.com/stiletilesceramic/embed/?cr=1&v=12&wp=435&rd=http%3A%2F%2Flocalhost%3A8080&rp=%2F#%7B%22ci%22%3A0%2C%22os%22%3A2835.7999999821186%2C%22ls%22%3A47.79999998211861%2C%22le%22%3A47.79999998211861%7D"  width="374"
                  height="330"></iframe>
                </v-col>
                <v-col cols="12" sm="6" class="twitterdiv">
                  <iframe
                    src="https://www.stile.com.pk/"
                    width="374"
                    height="330"
                  ></iframe>
                  <!-- <a height="340"
                    class="twitter-timeline"
                    href="https://twitter.com/Stile_tiles?ref_src=twsrc%5Etfw"
                    >Tweets by Stile_tiles</a
                  > -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="6" class="pl-lg-1 pt-0">
          <Weather> </Weather>
          <NewspapersAndMagazines class="d-sm-none d-lg-flex">
          </NewspapersAndMagazines>
        </v-col>
      </v-row>
      <v-row class="white py-md-3 mt--sm-0 pb-md-8">
        <v-container>
          <company-policy-component :isEditable="false" />
        </v-container>
      </v-row>

      <div class="footerclear"></div>
    </v-col>

    <Footer v-if="$route.path != '/' && !$route.path.includes('admin')" />
  </v-row>
</template>

<script>
import Greeting from "../components/Dashboard/Greeting.vue";
import CEOCorner from "../components/Dashboard/CEOCorner.vue";
import Slider from "../components/Dashboard/Slider.vue";
import NewsAndUpdates from "../components/Dashboard/NewsAndUpdates.vue";
import StarClub from "../components/Dashboard/StarClub.vue";
import DepartmentInFocus from "../components/Dashboard/DepartmentInFocus.vue";
import SpecialEvents from "../components/Dashboard/SpecialEvents.vue";
import Weather from "../components/Dashboard/Weather.vue";
import NewspapersAndMagazines from "../components/Dashboard/NewspapersAndMagazines.vue";
import Footer from "../components/Footer.vue";
import CompanyPolicyComponent from "../components/CompanyPolicyComponent.vue";

export default {
  name: "DashboardPage",
  props: {
    msg: String,
  },
  components: {
    Greeting,
    CEOCorner,
    Slider,
    NewsAndUpdates,
    StarClub,
    DepartmentInFocus,
    SpecialEvents,
    Weather,
    NewspapersAndMagazines,
    CompanyPolicyComponent,
    Footer,
  },
  methods: {
    RouteTo(url) {
      this.$router.push(url);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
