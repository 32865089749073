<template>
  <div
    class="d-flex flex-column fill-height ma-0"
    style="width: 100% !important"
    cols="12"
  >
    <v-overlay v-if="showPDFDialog" @click="showPDFDialog = false">
      <v-row class="justify-end mt-1" @click="showPDFDialog = false">
        <!-- style=" height: 900px !important" -->
        <v-col cols="12" class="text-right" @click="EmptyCommand($event)">
          <vue-pdf-app
            :pdf="PDFLink"
            style="
              width: 75vw !important;
              height: 90vh !important;
              max-width: 550px;
            "
            :config="PDFconfig"
          ></vue-pdf-app>
        </v-col>
      </v-row>
    </v-overlay>
    <v-row class="fill-height ma-0" style="width: 100% !important" cols="12">
      <v-col
        class="accentdark--text text-left"
        :class="{
          'xxlgFont fw-500 ': !isEditable,
          'lgFont fw-700': isEditable,
        }"
      >
        Company Policies
      </v-col>
      <v-col cols="auto" v-if="isEditable">
        <v-btn class="accent white--text" @click.stop="OpenPolicyPopup()"
          ><span class="material-icons-outlined mr-1 lgFont"
            >add_circle_outline</span
          >Add Policy</v-btn
        >
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="fill-height ma-0"
      style="width: 100% !important"
      cols="12"
    >
      <v-col cols="12" class="px-3">
        <v-chip-group
          mandatory
          show-arrows
          class="accentdark--text xsFont text-capitalize"
          active-class="accent--text"
        >
          <v-chip
            outlined
            active-class="accent--text"
            class="accentdark--text xsFont addcatchip"
            @click="OpenAddCategoryPopup()"
            v-if="isEditable"
          >
            <span class="material-icons-outlined mr-1 smFont"
              >add_circle_outline</span
            >
            Add Category
          </v-chip>
          <v-chip
            outlined
            @click="LoadPolicyByCategory(undefined)"
            active-class="accent--text accent"
            class="accentdark--text xsFont othercatchip"
          >
            All
          </v-chip>

          <v-chip
            v-for="(item, index) in CategoriesList"
            :key="index"
            outlined
            active-class="accent--text accent"
            class="accentdark--text xsFont othercatchip"
            @click="LoadPolicyByCategory(item)"
          >
            {{ item.title }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row
      class="d-flex fill-height mx-0 mt-0 pl-3"
      style="width: 100% !important"
    >
      <v-col
        cols="12"
        sm="4"
        md="3"
        class="pl-0"
        v-for="item in PoliciesList"
        :key="item.id"
      >
        <div class="border-accent border-1 rounded">
          <v-row
            no-gutters
            class="pa-2 rounded accentextra mb-0 align-center"
            @click="OpenFile(item.pdfUrl)"
          >
            <v-col cols="auto" class="line-height-0 pr-2">
              <img src="../assets/pdf.svg" style="height: 40px" />
            </v-col>
            <v-col class="xxxsFont min-wd-0"
              ><p class="pb-0 mb-1 accentdark--text">
                <v-tooltip bottom color="accentdark">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="text-truncate min-wd-content text-capitalize"
                    >
                      {{ item.title }}
                    </div>
                  </template>
                  <span> {{ item.title }}</span>
                </v-tooltip>
              </p>
              <p class="pb-0 mb-1 accentlight--text">
                <v-tooltip bottom color="accentdark">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="text-truncate min-wd-content"
                    >
                      <b>Category: </b>
                      <span class="text-capitalize">{{
                        item.categoryName
                      }}</span>
                    </div>
                  </template>
                  <span> {{ item.categoryName }}</span>
                </v-tooltip>
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters class="accentextra rounded" v-if="isEditable">
            <v-col cols="12" class="text-right">
              <v-btn
                text
                class="removebtn px-0"
                color="accentlight"
                @click="ConfirmRemove('policy', item.id)"
              >
                <span class="material-icons lgFont mr-1">delete</span>
                Remove</v-btn
              >
              <v-btn
                text
                class="editbtn"
                color="accentlight"
                @click="OpenEditPolicyPopup(item)"
              >
                <span class="material-icons-outlined lgFont mr-1">edit</span>
                Edit</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="addpolicyPopup" scrollable max-width="350px">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">
              {{
                PolicyPopup.popupType == "edit" ? "Edit Policy" : "Add Policy"
              }}</v-col
            >
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="addpolicyPopup = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :disabled="
                  !PolicyPopup.isValid ||
                  (PolicyPopup.SelectedPDFFile == undefined &&
                    PolicyPopup.popupType == 'create')
                "
                @click="SubmitForm"
                :loading="PolicyPopup.SavingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll>
          <v-card-text>
            <v-form ref="policyAddForm" v-model="PolicyPopup.isValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Policy Title"
                    outlined
                    dense
                    maxlength="20"
                    hide-details
                    class="capitaltextfield"
                    :rules="[
                      (v) =>
                        (v != undefined && !!v.trim()) || 'Title is required',
                    ]"
                    v-model="PolicyPopup.PolicyTitle"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="Category"
                    outlined
                    dense
                    hide-details
                    return-object
                    class="capitaltextfield"
                    item-text="title"
                    :items="CategoriesList"
                    :rules="[(v) => !!v || 'Title is required']"
                    v-model="PolicyPopup.SelectedCategory"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    outlined
                    class="accentlight--text text-notransform"
                    small
                    @click="onButtonClick"
                    >Browse</v-btn
                  >

                  <input
                    ref="fileuploadInput"
                    class="d-none"
                    type="file"
                    name="upload"
                    multiple
                    accept=".pdf,.doc"
                    @change="UploadFile($event)"
                  />
                </v-col>
                <v-col cols="12" class="pt-0 text-truncate">
                  {{ PolicyPopup.SelectedPDFFileName }}
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </vue-scroll>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mx-0 mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right pt-5 justify-end">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="addpolicyPopup = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :disabled="
                  !PolicyPopup.isValid ||
                  (PolicyPopup.SelectedPDFFile == undefined &&
                    PolicyPopup.popupType == 'create')
                "
                @click="SubmitForm"
                :loading="PolicyPopup.SavingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addcategoryDialog" scrollable max-width="450px">
      <v-card class="mb-0">
        <v-card-title class="accentdark--text">
          <v-row
            class="align-center borderBottom-1 border-accentxlight mx-n5 px-2"
          >
            <v-col class="accentdark--text">Add Category</v-col>
            <v-col cols="auto"> </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll>
          <v-card-text class="pt-3">
            <v-form
              ref="categoryForm"
              class="wd-100 py-3"
              v-model="CategoryPopup.isValid"
            >
              <v-row class="align-center">
                <v-col>
                  <v-text-field
                    label="Enter New Category"
                    outlined
                    class="capitaltextfield"
                    maxlength="30"
                    :rules="[(v) => !!v.trim() || 'Title is required']"
                    dense
                    hide-details
                    v-model="CategoryPopup.NewCategoryName"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" class="pl-0">
                  <v-btn
                    outlined
                    :disabled="!CategoryPopup.isValid"
                    :loading="CategoryPopup.SavingData"
                    class="accent--text fw-400 px-4 mdFont height-40"
                    small
                    @click="SaveCategory"
                    >Add</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
            <v-row no-gutters class="xsFont mt-0 accentlight--text">
              <v-col cols="12">
                <v-row
                  no-gutters
                  class="
                    border-1 border-secondary
                    rounded
                    pl-3
                    py-3
                    pr-0
                    mt-2
                    align-center
                  "
                  v-for="item in CategoryPopup.CetegoryList"
                  :key="item.id"
                >
                  <v-col class="min-wd-0">
                    Category Title
                    <div
                      class="
                        accentdark--text
                        text-truncate
                        fw-500
                        text-capitalize
                      "
                    >
                      {{ item.title }}
                    </div>
                  </v-col>
                  <v-col cols="auto" class="pr-0">
                    <v-btn
                      text
                      color="accentlight"
                      class="mr-3 editbtn px-0"
                      @click="OpenEditCategoryPopup(item)"
                    >
                      <span class="material-icons-outlined lgFont mr-1"
                        >edit</span
                      >Edit</v-btn
                    >
                  </v-col>
                  <v-col cols="auto" class="pr-0">
                    <v-btn
                      text
                      color="accentlight"
                      class="mr-3 removebtn px-0"
                      @click="CheckCategoryAndRemove(item.id)"
                    >
                      <span class="material-icons lgFont mr-1">delete</span
                      >Remove</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editcategoryDialog" scrollable max-width="450px">
      <v-card class="mb-0">
        <v-card-title class="accentdark--text">
          <v-row
            class="align-center borderBottom-1 border-accentxlight mx-n5 px-2"
          >
            <v-col class="accentdark--text">Edit Category</v-col>
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="editcategoryDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :disabled="!EditCategoryPopup.isValid"
                :loading="EditCategoryPopup.SavingData"
                @click="EditCategory()"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="editcategoryForm" v-model="EditCategoryPopup.isValid">
            <v-row class="align-center">
              <v-col>
                <v-text-field
                  label="Category Title"
                  outlined
                  maxlength="50"
                  class="capitaltextfield"
                  :rules="[(v) => !!v.trim() || 'Title is required']"
                  dense
                  hide-details
                  v-model="EditCategoryPopup.EditCategoryName"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mx-0 mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right pt-5 justify-end">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="editcategoryDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :disabled="!EditCategoryPopup.isValid"
                :loading="EditCategoryPopup.SavingData"
                @click="EditCategory()"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row>
            <v-col class="fw-500 mdFont accentdark--text">
              {{ ConfirmRemovePopup.Title }}
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                flat
                class="accentlight--text mr-2"
                small
                @click="showRemoveConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                flat
                class="accent--text"
                small
                @click="Remove(currentRemoveId)"
                :loading="ConfirmRemovePopup.SavingData"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apimethods from "../mixins/apimethods";
import helpermethods from "../mixins/helpermethods";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
export default {
  name: "CompanyPolicyComponent",
  props: ["isEditable"],
  mixins: [apimethods, helpermethods],
  components: { VuePdfApp },
  mounted() {},
  created() {
    this.LoadCategories();
  },
  data() {
    return {
      PDFconfig: {
        toolbar: {
          toolbarViewerRight: false,
          secondaryToolbarToggle: false,
        },
      },
      addpolicyPopup: false,
      addcategoryDialog: false,
      editcategoryDialog: false,
      showRemoveConfirmDialog: false,
      showPDFDialog: false,
      PDFLink: "",
      CategoriesList: [],
      SelectedCategory: undefined,
      PoliciesList: [],
      confirmRemoveType: "",
      currentRemoveId: 0,
      CategoryPopup: {
        NewCategoryName: "",
        isValid: false,
        SavingData: false,
        CetegoryList: [],
      },
      EditCategoryPopup: {
        isValid: false,
        CategoryModel: undefined,
        EditCategoryName: "",
        SavingData: false,
      },
      ConfirmRemovePopup: {
        SavingData: false,
        Title: "",
      },
      PolicyPopup: {
        popupType: "",
        isValid: false,
        SavingData: false,
        PolicyTitle: undefined,
        SelectedCategory: undefined,
        SelectedPDFFile: undefined,
        SelectedPDFFileName: undefined,
        ExistingModel: undefined,
        UpdateImage: false,
      },
    };
  },
  methods: {
    OpenAddCategoryPopup() {
      this.CategoryPopup.NewCategoryName = "";
      this.addcategoryDialog = true;
      this.CategoryPopup.CetegoryList = this.CategoriesList;
    },
    OpenPolicyPopup() {
      if (this.$refs.policyAddForm) {
        this.$refs.policyAddForm.resetValidation();
      }
      this.PolicyPopup.PolicyTitle = undefined;
      this.PolicyPopup.SelectedCategory = undefined;
      this.PolicyPopup.SelectedPDFFile = undefined;
      this.PolicyPopup.SelectedPDFFileName = undefined;
      this.PolicyPopup.UpdateImage = false;
      this.addpolicyPopup = true;
      this.PolicyPopup.popupType = "create";
    },
    OpenEditPolicyPopup(item) {
      this.PolicyPopup.PolicyTitle = item.title;
      var selCategory = this.CategoriesList.filter(
        (x) => x.id == item.categoryId
      );
      if (selCategory != undefined && selCategory.length > 0)
        this.PolicyPopup.SelectedCategory = selCategory[0];
      this.PolicyPopup.ExistingModel = item;
      this.PolicyPopup.SelectedPDFFile = undefined;
      this.PolicyPopup.SelectedPDFFileName = item.pdfName;
      this.PolicyPopup.UpdateImage = false;
      this.addpolicyPopup = true;
      this.PolicyPopup.popupType = "edit";
    },
    OpenEditCategoryPopup(itemToEdit) {
      this.editcategoryDialog = true;
      this.EditCategoryPopup.CategoryModel = itemToEdit;
      this.EditCategoryPopup.EditCategoryName = itemToEdit.title;
    },
    LoadCategories() {
      this.$store.commit("showLoadingModal");
      var self = this;
      self.CategoriesList = [];
      this.GetRequestWithAuthorization(
        "api/OurCompany/GetAllPolicyCategory",
        undefined
      )
        .then((res) => {
          self.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.CategoriesList = JSON.parse(JSON.stringify(res.data.data));
            self.CategoryPopup.CetegoryList = self.CategoriesList;

            self.LoadPolicies();
          }
        })
        .catch((ex) => {
          self.$store.commit("hideLoadingModal");
        });
    },
    LoadPolicyByCategory(item) {
      this.SelectedCategory = item;
      this.LoadPolicies();
    },
    LoadPolicies() {
      var selectedCategoryId = 0;
      if (
        this.SelectedCategory != undefined &&
        this.SelectedCategory.id != undefined
      )
        selectedCategoryId = this.SelectedCategory.id;
      this.$store.commit("showLoadingModal");
      var self = this;
      this.GetRequestWithAuthorization(
        "api/OurCompany/GetAllpolicyByCategoryId?cateogoryId=" +
          selectedCategoryId,
        undefined
      )
        .then((res) => {
          self.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.PoliciesList = JSON.parse(JSON.stringify(res.data.data));
          }
        })
        .catch((ex) => {
          self.$store.commit("hideLoadingModal");
        });
    },
    SaveCategory() {
      if (!this.CategoryPopup.isValid) {
        this.$refs.categoryForm.validate();
        return;
      }
      this.CategoryPopup.SavingData = true;
      var self = this;
      var bodyFormData = {};
      bodyFormData.title = this.CategoryPopup.NewCategoryName;
      var url = "api/OurCompany/AddCompanyPolicyCategory";
      var successmsg = "Category Added Successfully";
      var elementJson = JSON.stringify(bodyFormData);
      this.PostRequestWithAuthorization(url, undefined, elementJson)
        .then((res) => {
          this.CategoryPopup.SavingData = false;
          if (res != undefined && res.data != undefined) {
            if (res.data.data == true) {
              self.$notify({
                group: "notification",
                title: "Success",
                text: successmsg,
                type: "success",
              });
              self.LoadCategories();
              self.addcategoryDialog = false;
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.CategoryPopup.SavingData = false;
          console.log(ex);
        });
    },
    EditCategory() {
      this.EditCategoryPopup.SavingData = true;
      var self = this;
      var url = "api/OurCompany/EditCompanyPolicyCategory";
      var successmsg = "Category Updated Successfully";
      var categoryModel = this.EditCategoryPopup.CategoryModel;
      categoryModel.title = this.EditCategoryPopup.EditCategoryName;
      var elementJson = JSON.stringify(categoryModel);
      this.PostRequestWithAuthorization(url, undefined, elementJson)
        .then((res) => {
          this.editcategoryDialog = false;
          this.EditCategoryPopup.SavingData = false;
          if (res != undefined && res.data != undefined) {
            if (res.data.data == true) {
              self.$notify({
                group: "notification",
                title: "Success",
                text: successmsg,
                type: "success",
              });
              self.LoadCategories();
              //self.EditCategoryPopup = false;
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.EditCategoryPopup.SavingData = false;
          console.log(ex);
        });
    },
    CheckCategoryAndRemove(id) {
      var self = this;
      self.ConfirmRemovePopup.SavingData = false;
      this.GetRequestWithAuthorization(
        "api/OurCompany/CheckCategoryHasPolicy",
        [
          {
            Key: "categoryId",
            Value: id,
          },
        ]
      )
        .then((res) => {
          self.ConfirmRemovePopup.SavingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            if (res.data.data > 0) {
              self.$notify({
                group: "notification",
                title: "Message",
                text:
                  "You can't delete this category, as this category has " +
                  res.data.data +
                  ((res.data.data  == 1) ? " policy": " policies"),
                type: "error",
              });
            } else {
              self.ConfirmRemove("category", id);
            }
          }
        })
        .catch((ex) => {
          self.ConfirmRemovePopup.SavingData = false;
          console.log(ex);
        });
    },
    ConfirmRemove(type, id) {
      this.currentRemoveId = id;
      this.showRemoveConfirmDialog = true;
      this.confirmRemoveType = type;
      this.ConfirmRemovePopup.Title = "Are you sure you want to remove this?";
    },
    Remove() {
      if (this.confirmRemoveType == "category") this.DeleteCategory();
      else this.DeletePolicy();
    },
    DeleteCategory() {
      this.showRemoveConfirmDialog = false;
      var self = this;
      self.ConfirmRemovePopup.SavingData = true;
      this.GetRequestWithAuthorization(
        "api/OurCompany/RemoveCompanyPolicyCategory",
        [
          {
            Key: "categoryId",
            Value: this.currentRemoveId,
          },
        ]
      )
        .then((res) => {
          self.ConfirmRemovePopup.SavingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Category Removed Successfully",
              type: "success",
            });
            this.showRemoveConfirmDialog = false;
            self.LoadCategories();
          }
        })
        .catch((ex) => {
          self.ConfirmRemovePopup.SavingData = false;
          console.log(ex);
        });
    },
    DeletePolicy() {
      this.showRemoveConfirmDialog = false;
      var self = this;
      self.ConfirmRemovePopup.SavingData = true;
      this.GetRequestWithAuthorization("api/OurCompany/RemoveCompanyPolicy", [
        {
          Key: "policyId",
          Value: this.currentRemoveId,
        },
      ])
        .then((res) => {
          self.ConfirmRemovePopup.SavingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Policy Removed Successfully",
              type: "success",
            });
            this.showRemoveConfirmDialog = false;
            self.LoadCategories();
          }
        })
        .catch((ex) => {
          self.ConfirmRemovePopup.SavingData = false;
          console.log(ex);
        });
    },

    onButtonClick() {
      this.$refs.fileuploadInput.click();
    },
    UploadFile(event) {
      if (event.target.files != null && event.target.files.length > 0) {
        if (event.target.files[0]["type"] !== "application/pdf") {
          this.$notify({
            group: "notification",
            title: "Error",
            text: "Please select valid file",
            type: "error",
          });
          return;
        }
        var arrayFile = Array.from(event.target.files);

        this.PolicyPopup.SelectedPDFFile = arrayFile[0];
        this.PolicyPopup.UpdateImage = true;
        this.PolicyPopup.SelectedPDFFileName = arrayFile[0].name;
      }
    },
    OpenFile(url) {
      this.PDFLink = url;
      this.showPDFDialog = true;
      // window.open(url, "_blank");
    },
    SubmitForm() {
      if (this.PolicyPopup.popupType == "create") this.SavePolicy();
      else this.EditPolicy();
    },
    SavePolicy() {
      if (this.PolicyPopup.SelectedPDFFile == undefined) {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Please select File on creating",
          type: "error",
        });
        this.savingData = false;
        return;
      }
      var self = this;
      self.PolicyPopup.SavingData = true;
      var bodyFormData = new FormData();
      bodyFormData.append("title", this.PolicyPopup.PolicyTitle);
      bodyFormData.append("categoryId", this.PolicyPopup.SelectedCategory.id);
      bodyFormData.append("pDFName", this.PolicyPopup.SelectedPDFFileName);
      bodyFormData.append("file", this.PolicyPopup.SelectedPDFFile);

      this.PostFormRequestWithAuthorization(
        "api/OurCompany/AddCompanyPolicy",
        undefined,
        bodyFormData
      )
        .then((res) => {
          this.addpolicyPopup = false;
          self.PolicyPopup.SavingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Policy Added Successfully",
              type: "success",
            });
            self.dialog = false;
            self.LoadPolicies();
          }
        })
        .catch((ex) => {
          //this.$store.commit("hideLoadingModal");

          self.PolicyPopup.SavingData = false;
          console.log(ex);
        });
    },
    EmptyCommand(evt) {
      evt?.stopImmediatePropagation();
    },
    EditPolicy() {
      var self = this;
      self.PolicyPopup.SavingData = true;
      var bodyFormData = new FormData();
      bodyFormData.append("id", this.PolicyPopup.ExistingModel.id);
      bodyFormData.append("title", this.PolicyPopup.PolicyTitle);
      bodyFormData.append("categoryId", this.PolicyPopup.SelectedCategory.id);
      bodyFormData.append("pDFName", this.PolicyPopup.SelectedPDFFileName);
      bodyFormData.append("file", this.PolicyPopup.SelectedPDFFile);
      bodyFormData.append("updateImage", this.PolicyPopup.UpdateImage);

      this.PostFormRequestWithAuthorization(
        "api/OurCompany/EditCompanyPolicy",
        undefined,
        bodyFormData
      )
        .then((res) => {
          this.addpolicyPopup = false;
          self.PolicyPopup.SavingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Policy Updated Successfully",
              type: "success",
            });
            self.LoadPolicies();
          }
        })
        .catch((ex) => {
          self.PolicyPopup.SavingData = false;
          console.log(ex);
        });
    },
  },
};
</script>
