<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col
              class="lgFont accentdark--text fw-700"
              @click.stop="ViewMoreEvent = true"
            >
              Events
            </v-col>
            <v-col cols="auto">
              <!-- <v-btn
           
                class="accent white--text"
                @click.stop="addEventTypeDialog = true"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                >Add Event Type</v-btn
              > -->
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="accent white--text"
                @click.stop="openEventDialog('')"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                >Add Events</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="fill-height p-relative mb-0">
            <v-col>
              <v-row class="fill-height p-absolute wd-100 eventcal">
                <vue-scroll :ops="ops">
                  <v-col class="pt-0">
                    <v-row class="d-sm-none">
                      <v-col>
                        <v-toolbar-title
                          class="accentdark--text"
                          v-if="$refs.calendar"
                          >{{ $refs.calendar.title }}
                        </v-toolbar-title>
                      </v-col>
                    </v-row>
                    <v-sheet height="64" class="px-0">
                      <v-toolbar flat class="px-0">
                        <v-btn
                          filled
                          class="
                            mr-4
                            ml-n4
                            mdFont1
                            todaybtn
                            accentdark--text
                            elevation-0
                            text-capitalize
                          "
                          color="secondaryxxLight"
                          @click="setToday"
                        >
                          Today
                        </v-btn>
                        <v-btn
                          outlined
                          text
                          small
                          class="py-4 mr-3"
                          @click="prev"
                        >
                          <v-icon> mdi-chevron-left </v-icon>
                        </v-btn>
                        <v-btn outlined class="py-4" text small @click="next">
                          <v-icon> mdi-chevron-right </v-icon>
                        </v-btn>
                        <v-toolbar-title
                          class="pl-3 accentdark--text d-none d-sm-inline-block"
                          v-if="$refs.calendar"
                        >
                          {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          class="d-none d-sm-block"
                          color="accent"
                          @click.stop="openEventTypeDialog"
                          ><span class="material-icons-outlined mr-1 lgFont"
                            >add_circle_outline</span
                          >Add Type</v-btn
                        >
                        <v-col cols="3" class="pr-0 mr-n3 d-none d-sm-block">
                          <v-combobox
                            outlined
                            label="Event Type"
                            dense
                            hide-details
                            return-object
                            item-text="title"
                            v-model="filterEventType"
                            :items="EventTypesListWithAll"
                            @change="OnEventTypeChange"
                            spellcheck="false"
                          >
                          </v-combobox>
                        </v-col>
                      </v-toolbar>
                    </v-sheet>
                    <v-row no-gutters class="align-center mb-3 d-sm-none">
                      <v-col>
                        <v-btn
                          outlined
                          color="accent"
                          @click.stop="openEventTypeDialog"
                          ><span class="material-icons-outlined mr-1 lgFont"
                            >add_circle_outline</span
                          >Add Type</v-btn
                        >
                      </v-col>

                      <v-col cols="6" class="pr-0">
                        <v-combobox
                          outlined
                          label="Event Type"
                          dense
                          hide-details
                          return-object
                          item-text="title"
                          v-model="filterEventType"
                          :items="EventTypesListWithAll"
                          @change="OnEventTypeChange"
                        >
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <v-sheet height="600" class="eventcal homemodalcal">
                      <v-calendar
                        ref="calendar"
                        v-model="focus"
                        color="secondaryxxLight"
                        :events="events"
                        :event-color="getEventColor"
                        :type="type"
                        :disabled-dates="[1, 7]"
                        @click:more="ViewMore"
                        @click:event="ViewEventClick"
                        @change="updateRange"
                        @click:date="DateClick"
                        :min="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        event-ripple="false"
                        event-overlap-mode="stack"
                        event-overlap-threshold="20"
                      >
                        <!-- <template v-slot:event="{ event }">
                          {{ event.name }}
                        </template> -->
                        <template v-slot:event="{ event }">
                          <v-tooltip bottom color="accentdark">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                                class="
                                  text-truncate
                                  min-wd-content
                                  text-capitalize
                                "
                              >
                                {{ event.name }}
                              </div>
                            </template>
                            <span> {{ event.name }}</span>
                          </v-tooltip>
                        </template>
                      </v-calendar>
                    </v-sheet>
                  </v-col>
                </vue-scroll>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog
      v-model="addEventDialog"
      content-class="dashboardmodal"
      max-width="350"
    >
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col>Add Event</v-col>
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click.stop="addEventDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :loading="EventPopup.savingData"
                @click="SaveEvent"
                :disabled="!EventPopup.isValid || EventPopup.savingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form ref="modalForm" v-model="EventPopup.isValid">
            <v-row
              ><v-col cols="12">
                <v-select
                  label="Event Type"
                  outlined
                  dense
                  hide-details
                  return-object
                  item-text="title"
                  :items="EventTypesList"
                  v-model="EventPopup.EventType"
                  :rules="[(v) => !!v || 'Item is required']"
                  spellcheck="false"
                ></v-select></v-col
            ></v-row>
            <v-row
              ><v-col cols="12">
                <v-autocomplete
                  label="Employee"
                  outlined
                  dense
                  hide-details
                  return-object
                  v-model="EventPopup.SelectedEmployee"
                  item-text="Value"
                  :items="EmployeesList"
                ></v-autocomplete></v-col
            ></v-row>
            <v-row
              ><v-col cols="12">
                <v-menu
                  ref="EventDatemenu"
                  v-model="EventDatemenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="EventPopup.Date"
                      label="Date"
                      outlined
                      dense
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :disabled="EventPopup.DisabledDate"
                      :rules="[(v) => !!v || 'Item is required']"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="EventPopup.Date"
                    :min="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right justify-end">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click.stop="addEventDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :loading="EventPopup.savingData"
                @click="SaveEvent"
                :disabled="!EventPopup.isValid || EventPopup.savingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addEventTypeDialog"
      content-class="dashboardmodal"
      max-width="350"
    >
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col>Add Event Type</v-col>
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click.stop="addEventTypeDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :loading="EventTypePopup.savingData"
                @click="SaveEventType"
                :disabled="!EventTypePopup.isValid || EventTypePopup.savingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form ref="modalForm" v-model="EventTypePopup.isValid">
            <v-row
              ><v-col cols="12">
                <v-text-field
                  label="Event Type"
                  outlined
                  dense
                  hide-details
                  maxlength="30"
                  v-model="EventTypePopup.EventName"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field></v-col
            ></v-row>
            <v-row
              ><v-col cols="12">
                <v-select
                  label="Event Color"
                  outlined
                  dense
                  hide-details
                  item-text="name"
                  :items="ColorList"
                  return-object
                  v-model="EventTypePopup.EventColor"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-select></v-col
            ></v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right justify-end">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click.stop="addEventTypeDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :loading="EventTypePopup.savingData"
                @click="SaveEventType"
                :disabled="!EventTypePopup.isValid || EventTypePopup.savingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ViewMoreEvent"
      content-class="dashboardmodal"
      max-width="600"
    >
      <v-card class="mb-0">
        <v-card-text class="eventcaldiv" v-if="MorePopup.EventList">
          <v-row
            class="
              accentlight--text
              py-3
              xsFont
              align-center
              borderLeft-4
              border-accent
              ml-n4
            "
            :style="'border-color:' + item.FullItem.colorHex + '!important;'"
            v-for="item in MorePopup.EventList"
            :key="item.id"
          >
            <!-- <v-row
              v-if="
                item.FullItem.employeeName != undefined &&
                item.FullItem.employeeName != ''
              "
            > -->

            <v-col
              cols="7"
              class="min-wd-0 col-sm order-0"
              v-if="
                !(
                  item.FullItem.employeeName != undefined &&
                  item.FullItem.employeeName != ''
                )
              "
            >
              Event Type
              <div class="accentdark--text text-truncate fw-500">
                {{ item.name }}
              </div>
            </v-col>
            <v-col
              cols="7"
              class="min-wd-0 col-sm order-0"
              v-if="
                item.FullItem.employeeName != undefined &&
                item.FullItem.employeeName != ''
              "
            >
              Employee
              <div class="accentdark--text text-truncate fw-500">
                {{ item.FullItem.employeeName }}
              </div>
            </v-col>

            <v-col
              cols="6"
              sm="3"
              class="pl-sm-0 order-3 order-sm-4"
              v-if="
                item.FullItem.employeeName != undefined &&
                item.FullItem.employeeName != ''
              "
            >
              Department
              <div class="accentdark--text text-truncate fw-500">
                {{ item.FullItem.department }}
              </div>
            </v-col>

            <v-col
              cols="auto"
              class="pl-0 order-1 order-sm-4 text-right"
              v-if="item.FullItem.eventViewType != 1"
            >
              <v-btn
                color="accentlight"
                class="removebtn"
                text
                @click="confirmRemove(item.FullItem.id)"
              >
                <span class="material-icons mdFont mr-1">delete</span>
                Remove</v-btn
              >
            </v-col>
            <!-- </v-row>
            <v-row v-else>
              <v-col class="min-wd-0">
                Event Type
                <div class="accentdark--text text-truncate fw-500">
                  {{ item.name }}
                </div>
              </v-col>

              <v-col
                cols="auto"
                class="pl-0"
                v-if="item.FullItem.eventViewId != 1"
              >
                <v-btn
                  color="accentlight"
                  class="removebtn"
                  text
                  @click="confirmRemove(item.FullItem.id)"
                >
                  <span class="material-icons mdFont mr-1">delete</span>
                  Remove</v-btn
                >
              </v-col>
            </v-row> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row>
            <v-col class="fw-500 mdFont accentdark--text">
              Are you sure you want to remove this item?
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="showRemoveConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :loading="RemoveLoading"
                @click="RemoveEvent(currentRemoveId)"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apimethods from "../../mixins/apimethods";

export default {
  mixins: [apimethods],
  data: () => ({
    ops: {
      scrollPanel: {
        scrollingX: false,
      },
    }, // Your options
    showRemoveConfirmDialog: false,
    currentRemoveId: 0,
    focus: "",
    EndingDate: "",
    StartingDate: "",
    addEventDialog: false,
    addEventTypeDialog: false,
    startingticks: 0,
    ViewMoreEvent: false,
    endingticks: 0,
    filterEventType: undefined,
    EventTypePopup: {
      savingData: false,
      isValid: false,
      EventName: "",
      EventColor: undefined,
    },
    EventPopup: {
      savingData: false,
      isValid: false,
      EventType: undefined,
      SelectedEmployee: undefined,
      DisabledDate: false,
      Date: "",
    },
    ColorList: [],
    EventTypesListWithAll: [],
    EventTypesList: [],
    EmployeesList: [],
    formValid: false,
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    MorePopup: {
      EventList: [],
    },
    RemoveLoading: false,
  }),
  watch: {
    addEventDialog: function (val) {
      if (
        val == true &&
        (this.EmployeesList == undefined || this.EmployeesList.length == 0)
      )
        this.fetchEmployeeList();
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  created() {
    this.LoadInitialData();
  },
  methods: {
    ViewMore(moredata) {
      var moment = require("moment");
      var moreSelectedDate = moment(moredata.date);
      this.MorePopup.EventList = this.events.filter(
        (x) =>
          x.start.getDate() == moreSelectedDate.date() &&
          x.start.getMonth() == moreSelectedDate.month()
      );
      this.ViewMoreEvent = true;
      //this.focus = date;
    },
    ViewEventClick(moredata) {
      var moment = require("moment");
      var moreSelectedDate = moment(moredata.day?.date);
      this.MorePopup.EventList = this.events.filter(
        (x) =>
          x.start.getDate() == moreSelectedDate.date() &&
          x.start.getMonth() == moreSelectedDate.month()
      );
      this.ViewMoreEvent = true;
      //this.focus = date;
    },
    openEventTypeDialog() {
      this.addEventTypeDialog = true;
      this.EventTypePopup.EventName = "";
      this.EventTypePopup.EventColor = undefined;
    },
    openEventDialog(type) {
      this.addEventDialog = true;
      this.EventPopup.SelectedEmployee = undefined;
      this.EventPopup.EventType = undefined;
      this.EventPopup.Date = "";
      if (type == "dateSelected") {
        this.EventPopup.DisabledDate = true;
      } else {
        this.EventPopup.DisabledDate = false;
      }
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      this.startingticks = min.getTime() * 10000 + 621355968000000000;
      this.endingticks = max.getTime() * 10000 + 621355968000000000;
      this.fetchEventOfSelectedMonth(this.startingticks, this.endingticks);
      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    LoadInitialData() {
      this.fetchDatafromInd("Colors").then((colors) => {
        this.ColorList = colors;
      });

      this.fetchDatafromInd("EventTypes").then((eventTypes) => {
        this.EventTypesList = eventTypes;
        this.LoadEventTypesWithAll(eventTypes);
        this.filterEventType = this.EventTypesListWithAll[0];
      });
    },

    LoadEventTypesWithAll(compEventTypes) {
      this.EventTypesListWithAll = [];
      this.EventTypesListWithAll.push({
        id: 0,
        title: "All",
        colorHex: "#000000",
      });
      compEventTypes.forEach((element) => {
        this.EventTypesListWithAll.push(element);
      });
    },

    DateClick(evt) {
      var currentDate = new Date();
      var moment = require("moment");
      var date1 = moment(currentDate);
      var date2 = moment(evt.date);
      if (date1 < date2) {
        this.openEventDialog("dateSelected");
        this.EventPopup.Date = evt.date;
      } else {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Please select future date",
          type: "error",
        });
      }
    },
    confirmRemove(eventId) {
      this.$data.showRemoveConfirmDialog = true;
      this.$data.currentRemoveId = eventId;
    },
    RemoveEvent(eventId) {
      this.$store.commit("showLoadingModal");
      this.RemoveLoading = true;
      var self = this;

      this.GetRequestWithAuthorization("api/Event/RemoveEvent", [
        {
          Key: "eventId",
          Value: eventId,
        },
      ])
        .then((res) => {
          this.RemoveLoading = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            this.$data.showRemoveConfirmDialog = false;
            self.ViewMoreEvent = false;
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Event removed Successfully",
              type: "success",
            });
            self.fetchEventOfSelectedMonth(
              this.startingticks,
              this.endingticks
            );
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.RemoveLoading = false;
          console.log(ex);
        });
    },
    fetchEmployeeList() {
      this.$store.commit("showLoadingModal");

      this.GetRequestWithAuthorization(
        "api/Employee/GetAllEmployees",
        undefined
      )
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            this.EmployeesList = res.data.data;
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },

    SaveEventType() {
      //var self = this;
      this.EventTypePopup.savingData = true;

      var object = {};
      if (this.EventTypePopup.EventColor != undefined)
        object.colorHex = this.EventTypePopup.EventColor.hex;
      object.title = this.EventTypePopup.EventName;
      var elementJson = JSON.stringify(object);

      this.PostRequestWithAuthorization(
        "api/event/AddEventType",
        undefined,
        elementJson
      )
        .then((res) => {
          this.EventTypePopup.savingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            this.$notify({
              group: "notification",
              title: "Message",
              text: "Event Type Added Successfully",
              type: "success",
            });
            this.saveIndexedData("EventTypes", res.data.data);
            this.EventTypesList = res.data.data;
            this.LoadEventTypesWithAll(res.data.data);
            this.addEventTypeDialog = false;
            this.EventTypePopup.EventName = "";
            this.EventTypePopup.EventColor = undefined;
          }
        })
        .catch((ex) => {
          this.EventTypePopup.savingData = false;
        });
    },

    OnEventTypeChange(evtType) {
      //this.filterEventType = evtType;
      this.fetchEventOfSelectedMonth(this.startingticks, this.endingticks);
    },

    SaveEvent() {
      //var self = this;
      this.EventPopup.savingData = true;

      var object = {};
      if (this.EventPopup.EventType != undefined)
        object.typeId = this.EventPopup.EventType.id;
      object.eventDate = this.EventPopup.Date;
      if (this.EventPopup.SelectedEmployee != undefined)
        object.employeeId = this.EventPopup.SelectedEmployee.Key;
      var elementJson = JSON.stringify(object);

      this.PostRequestWithAuthorization(
        "api/event/AddEvent",
        undefined,
        elementJson
      )
        .then((res) => {
          this.EventPopup.savingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined &&
            res.data.data != false
          ) {
            this.fetchEventOfSelectedMonth(
              this.startingticks,
              this.endingticks
            );
            this.$notify({
              group: "notification",
              title: "Message",
              text: "Event  Added Successfully",
              type: "success",
            });
            this.addEventDialog = false;
            this.EventPopup.EventType = undefined;
            this.EventPopup.SelectedEmployee = undefined;
            this.EventPopup.Date = "";
          } else {
            this.$notify({
              group: "notification",
              title: "Error",
              text: "Error saving event",
              type: "error",
            });
          }
        })
        .catch((ex) => {
          this.EventTypePopup.savingData = false;
        });
    },

    fetchEventOfSelectedMonth(strtticks, endticks) {
      this.$store.commit("showLoadingModal");

      var params = [
        {
          Key: "fromUTCticks",
          Value: strtticks,
        },
        {
          Key: "toUTCticks",
          Value: endticks,
        },
      ];

      if (
        this.filterEventType != undefined &&
        this.filterEventType.id != undefined &&
        this.filterEventType.id != 0
      ) {
        params.push({
          Key: "typeId",
          Value: this.filterEventType.id,
        });
      }
      else
      {
        params.push({
          Key: "typeId",
          Value: 0,
        });
      }
      this.GetRequestWithAuthorization("api/Event/GetEventFromRange", params)
        .then((res) => {
          debugger;
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            var eventsRes = res.data.data;
            let events = [];
            let i = 0;
            eventsRes.forEach((element) => {
              i++;
              var eventStartDate = new Date(element.eventDate);
              eventStartDate.setSeconds(eventStartDate.getSeconds() + i);
              let eventEndDate = new Date(element.eventDate);
              eventEndDate.setHours(eventEndDate.getHours() + 23);
              events.push({
                name: element.eventTypeTitle,
                start: eventStartDate,
                end: eventEndDate,
                color: element.colorHex,
                FullItem: element,
              });
            });
            this.events = events;
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
