<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col class="lgFont accentdark--text fw-700">
              Department Directory
            </v-col>
            <v-col cols="auto">
              <v-btn class="accent white--text" @click.stop="AddDepartment"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                >Add Department</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="fill-height p-relative mb-0">
            <v-col cols="min-wd-0">
              <v-row class="fill-height p-absolute wd-100 employeedirectory">
                <vue-scroll :ops="ops">
                  <v-col cols="12">
                    <v-card
                      outlined
                      class="px-3 py-4 mb-2"
                      v-for="(item, index) in departmentList"
                      :key="item.Id"
                    >
                      <v-row class="accentlight--text xsFont align-start">
                        <v-col cols="2" sm="1" class="order-0">
                          S.No
                          <div class="accentdark--text text-truncate fw-500">
                            {{ index + 1 }}
                          </div>
                        </v-col>
                        <v-col cols="7" sm="2" class="order-1">
                          Department Title
                          <div class="accentdark--text text-truncate fw-500">
                            {{ item.title }}
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          class="
                            col-sm
                            min-wd-0
                            pt-0 pt-sm-3
                            pb-0 pb-sm-3
                            order-sm-2 order-3
                          "
                        >
                          <div class="accentdark--text fw-500"></div>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="auto"
                          class="order-sm-3 order-4 py-0 py-sm-3 px-sm-0"
                        >
                          <v-btn
                            text
                            class="removebtn px-0 mr-2"
                            color="accentlight"
                            @click="ConfirmRemove(item.id, item.title)"
                          >
                            <span class="material-icons lgFont mr-1"
                              >delete</span
                            >
                            Remove</v-btn
                          >

                          <v-btn
                            text
                            class="editbtn"
                            color="accentlight mr-2"
                            @click="editDepartment(item)"
                          >
                            <span class="material-icons-outlined lgFont mr-1"
                              >edit</span
                            >
                            Edit</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </vue-scroll>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog
      v-model="addEditDeptDialog"
      content-class="dashboardmodal"
      max-width="440"
    >
      <v-card class="mb-0">
        <v-form ref="deptForm" v-model="deptFormValid">
          <v-card-title
            class="accentdark--text borderBottom-1 border-accentxlight"
          >
            <v-row class="align-center">
              <v-col>{{ deptPopupTitle }} Department</v-col>
              <v-col cols="auto" class="d-none d-sm-block">
                <v-btn
                  outlined
                  class="accentlight--text mr-2"
                  small
                  @click="addEditDeptDialog = false"
                  >Cancel</v-btn
                >

                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!allowSave"
                  :loading="isSaving"
                  outlined
                  class="accent--text"
                  small
                  @click="saveDepartment"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Department Title"
                  outlined
                  dense
                  maxlength="50"
                  hide-details
                  :rules="[(v) => !!v.trim() || 'Title is required']"
                  v-model="currentDepartment.title"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
            <v-row class="align-center mx-0 mb-n1 text-right justify-end">
              <v-col cols="auto" class="text-right pt-5 justify-end">
                <v-btn
                  outlined
                  class="accentlight--text mr-2"
                  small
                  @click="addEditDeptDialog = false"
                  >Cancel</v-btn
                >

                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!allowSave"
                  :loading="isSaving"
                  outlined
                  class="accent--text"
                  small
                  @click="saveDepartment"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row>
            <v-col class="fw-500 mdFont accentdark--text">
              Are you sure you want to remove this department ({{
                currentRemoveName
              }})?
            </v-col>
          </v-row>
          <v-row>
            <v-col class="fw-500 smFont error--text">
              Note: This action cannot be undone.
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="showRemoveConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="RemoveDepartment(currentRemoveId)"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apimethods from "../../mixins/apimethods";

export default {
  data() {
    return {
      showRemoveConfirmDialog: false,
      currentRemoveId: 0,
      currentRemoveName: 0,
      ops: {
        scrollPanel: {
          scrollingX: false,
        },
      },
      addEditDeptDialog: false,
      isSaving: false,
      deptFormValid: false,
      departmentList: {},
      deptPopupTitle: "Add",
      currentDepartment: {},
    };
  },
  name: "DepartmentDirectoryPage",
  props: {
    msg: String,
  },
  mounted() {
    this.GetAllDepartments();
  },
  computed: {
    allowSave() {
      if (
        this.currentDepartment.title == null ||
        this.currentDepartment.title == undefined ||
        this.currentDepartment.title.trim() == ""
      ) {
        return false;
      }
      return true;
    },
  },
  mixins: [apimethods],
  methods: {
    resetScroll(movetoBottom) {
      var scrollView = document.getElementsByClassName("desgScroll");
      setTimeout(() => {
        if (movetoBottom) {
          scrollView[0].childNodes[0].scrollTop =
            scrollView[0].childNodes[0].scrollHeight;
        } else {
          scrollView[0].childNodes[0].scrollTop = 0;
        }
      }, 0);
    },
    AddDepartment() {
      this.deptPopupTitle = "Add";
      this.currentDepartment = {};
      this.addEditDeptDialog = true;
      this.$refs.deptForm.resetValidation();
      this.resetScroll();
    },
    editDepartment(dept) {
      this.deptPopupTitle = "Edit";
      this.currentDepartment = JSON.parse(JSON.stringify(dept));
      this.addEditDeptDialog = true;
      this.$refs.deptForm.resetValidation();
      this.resetScroll();
    },
    ConfirmRemove(deptId, name) {
      this.currentRemoveId = deptId;
      this.currentRemoveName = name;
      this.showRemoveConfirmDialog = true;
    },
    RemoveDepartment(deptId) {
      this.$store.commit("showLoadingModal");
      this.showRemoveConfirmDialog = false;
      var self = this;
      this.GetRequestWithAuthorization("api/Department/Remove", [
        {
          Key: "departmentId",
          Value: this.currentRemoveId,
        },
      ])
        .then((res) => {
          this.$store.commit("hideLoadingModal");

          if (res != undefined && res.data != undefined) {
            if (res.data.status == true) {
              self.$notify({
                group: "notification",
                title: "Message",
                text: "Department Removed Successfully",
                type: "success",
              });
              self.GetAllDepartments();
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          console.log(ex);
        });
    },
    GetAllDepartments() {
      this.$store.commit("showLoadingModal");
      var self = this;
      this.GetRequestWithAuthorization("api/Department/Get", undefined)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.departmentList = res.data.data;
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
    saveDepartment() {
      var isEdit =
        this.currentDepartment.id != null &&
        this.currentDepartment.id != undefined &&
        this.currentDepartment.id != 0;

      this.isSaving = true;
      this.$store.commit("showLoadingModal");
      var self = this;
      var url = "api/Department/".concat(isEdit ? "Edit" : "Add");
      this.PostRequestWithAuthorization(
        url,
        undefined,
        JSON.stringify(this.currentDepartment)
      )
        .then((res) => {
          self.isSaving = false;
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            if (res.data.data == true) {
              self.$notify({
                group: "notification",
                title: "Success",
                text: "Department Information updated successfully.",
                type: "success",
              });
              self.addEditDeptDialog = false;
              self.GetAllDepartments();
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          self.isSaving = false;
          this.$store.commit("hideLoadingModal");
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
