<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="3" class="order-1 order-sm-0" sm="12">
              <img
                v-if="weatherIcon"
                :src="require(`../../assets/OpenWeather/${weatherIcon}.png`)"
                class="cloudimg mb-2"
              />
              <p class="accentlight--text mb-0 fw-700 xlgFont">
                {{ Temperature }}&deg;C
              </p>
            </v-col>
            <v-col cols="9" sm="12" class="order-0 order-sm-1">
              <v-row no-gutters>
                <v-col cols="12" class="order-1 order-sm-0">
                  <p class="accentdark--text fw-500 mb-0 mb-sm-3">
                    {{ Day }}, {{ Time }}
                  </p>
                </v-col>
                <v-col cols="12" class="order-0 order-sm-1">
                  <p class="accentdark--text xxlgFont mb-2 fw-400">
                    {{ Greeting }},
                  </p>
                  <p
                    class="
                      accentdark--text
                      xxlgFont
                      fw-900
                      pt-2 pt-sm-1
                      mb-sm-0
                    "
                  >
                    {{ FirstName }}!
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Greeting",
  data() {
    return {
      Temperature: "",
      Day: "",
      Time: "",
      Greeting: "",
      FirstName: "",
      dayType: "",
      weatherIcon: "",
    };
  },
  created() {
    setInterval(this.getNow, 1000);
    var userDetailStr = localStorage.getItem("UserDetail");
    if (userDetailStr != null) {
      var userDetail = JSON.parse(userDetailStr);
      this.FirstName = userDetail.firstName;
      this.Temperature = userDetail.cityTemperature;
      this.weatherIcon = userDetail.weatherConditionIcon;
    }
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date();
    this.Day = days[d.getDay()];

    //this.Time = d.getHours() + ":" + d.getMinutes();
    var hours = d.getHours();
    if (hours > 5 && hours < 12)  {
      this.Greeting = "Good Morning";
    } 

    if ((hours >= 12 && hours < 14 )||(hours > 15 && hours <= 17 ) )  {
      this.Greeting = "Good Afternoon";
    } 

    if (hours > 14 && hours <= 15)  {
      this.Greeting = "Lunch Time";
    } 

    if (hours > 17 && hours <= 19)  {
      this.Greeting = "Good Evening";
    } 

    if (hours > 19 || hours <= 5)  {
      this.Greeting = "Good Night";
    } 
  },
  methods: {
    getNow: function () {
      const today = new Date();
      this.Time = today.toLocaleString("en-US", { timeStyle: "short" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
