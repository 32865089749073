import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import radiooff from "@/components/icons/radio-off.vue";
import radioon from "@/components/icons/radio-on.vue";
import checkon from "@/components/icons/checkbox-on.vue";
import checkoff from "@/components/icons/checkbox-off.vue";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      options: {
        customProperties: true
      },
      themes: {
        light: {
          base: "#f8f8f8",
          white: "#ffffff",
          accentdark: "#223c61",
          accent: "#018fcb",
          accentextra: "#fafbfe",
          accentxdark: "#014399",
          accentlight:"#909db0",
          accentxlight:"#d2d8df",
          black:"#000000",
          secondary:"#d2d7de",
          secondaryLight:"#5f7599 ",
          secondaryxLight:"#dbdbdb ",
          secondaryxxLight:"#cce9f5 ",
          secondarydark:"#98a4b5",
          success:'#1d6f42',
          dblue:"#003783"


        }
      }
    },
    icons: {
      values: {
        checkboxOn: {
          component: checkon
        },
        checkboxOff: {
          component: checkoff
        },
        radioOn: {
          component: radioon
        },
        radioOff: {
          component: radiooff
        }
      }
    }
  });
