
export default {
    created() {
        window.addEventListener("resize", this.allocateseemorediv);
        this.allocateseemorediv(null);
    },
    destroyed() {
        window.removeEventListener("resize", this.allocateseemorediv);
    },
    data() {
        return {
            isMobile: false,
            Linkrules: [
                (value) => this.isVideoURL(value) || "URL is not valid",
            ],
            NullableLinkrules: [
                (value) => this.isEmptyOrVideoURL(value) || "URL is not valid",
            ],
        };
    },
    methods: {
        generateUUID() {
            // Public Domain/MIT
            var d = new Date().getTime();
            if (
                typeof performance !== "undefined" &&
                typeof performance.now === "function"
            ) {
                d += performance.now(); //use high-precision timer if available
            }
            var newGuid = "xxxxxxxx".replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
            });

            return newGuid;
        },
        allocateseemorediv(e) {
            this.$data.isMobile = window.innerWidth < 1263;

            var divsCollection = document.getElementsByClassName("seemorediv");

            if (divsCollection && divsCollection.length > 0) {
                for (let div of divsCollection) {
                    if (div.children[0].offsetHeight > div.offsetHeight) {
                        div.children[1].classList.remove('d-none');
                    }
                }
            }
        },
        isFileImage(file) {
            return file && file["type"].split("/")[0] === "image";
        },
        isVideoURL(str) {
            let url;
            var checkVideo = true;
            var BreakException = {};
            try {
                url = new URL(str);
                var videoLinkformats = [
                    ".flv",
                    ".wmv",
                    ".mov",
                    ".mp4",
                    ".avi",
                    ".webm",
                    ".mkv",
                    "youtube",
                    "drive.google"
                ];
                videoLinkformats.forEach((element) => {
                    if (str.toLowerCase().includes(element) == true) {
                        checkVideo = true;
                        throw BreakException;
                    } else checkVideo = false;
                });
                // checkVideo = videoLinkformats.includes((x) =>
                //   str.toLowerCase().includes(x)
                // );
            } catch (e) {
                if (e !== BreakException) return false;
            }

            return (
                checkVideo && (url.protocol === "http:" || url.protocol === "https:")
            );
        },
        isEmptyOrVideoURL(str) {
            if (str == null || str == undefined || str.trim() == "")
                return true;

            return this.isVideoURL(str);
        },
        CheckIfMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true;
            }
            return false;
        }

    }
}
