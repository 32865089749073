<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col class="lgFont accentdark--text fw-700">
              All Newspapers
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="accent white--text"
                @click.stop="OpenDialog('create')"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                >Add Newspaper</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="fill-height p-relative mb-0">
            <v-col>
              <v-row class="fill-height p-absolute wd-100">
                <vue-scroll>
                  <v-col cols="12">
                    <v-row class="align-center">
                      <v-col cols="auto" class="accent--text fw-500 mdFont1"
                        >Active Newspaper</v-col
                      >
                    </v-row>

                    <v-row class="align-center">
                      <v-col
                        v-for="item in listofActiveNewspaper"
                        :key="item.id"
                        cols="12"
                        sm="6"
                        md="4"
                        class="newslist"
                      >
                        <v-row class="ma-0">
                          <v-col
                            cols="12"
                            class="border-1 border-accentxlight rounded"
                          >
                            <v-row class="base rounded">
                              <v-col cols="12" class="text-center">
                                <img :src="item.thumbnailURL" />
                              </v-col>
                              <v-col cols="12" class="pt-0">
                                <v-row
                                  no-gutters
                                  class="justify-center align-center"
                                >
                                  <v-col cols="auto" class="mr-2"
                                    ><v-btn
                                      text
                                      class="editbtn px-0 px-sm-3"
                                      color="accentlight"
                                      @click="OpenDialog('edit', item)"
                                    >
                                      <span
                                        class="
                                          material-icons-outlined
                                          lgFont
                                          mr-1
                                        "
                                        >edit</span
                                      >
                                      Edit</v-btn
                                    ></v-col
                                  >
                                  <v-col cols="auto" class="mr-2"
                                    ><v-btn
                                      text
                                      class="removebtn px-0 px-sm-3"
                                      color="accentlight"
                                      @click="confirmRemove(item.id)"
                                    >
                                      <span class="material-icons lgFont mr-1"
                                        >delete</span
                                      >
                                      Remove</v-btn
                                    ></v-col
                                  >
                                  <v-col cols="auto">
                                    <v-checkbox
                                      small
                                      hide-details
                                      v-model="item.isActive"
                                      class="mt-0 pt-0 customcheckbox"
                                      label="ACTIVE"
                                      @change="
                                        ChangeActiveStatus(
                                          item.id,
                                          item.isActive
                                        )
                                      "
                                    ></v-checkbox>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row class="align-center">
                      <v-col
                        cols="auto"
                        class="accentlight--text fw-500 mdFont1"
                        >Inactive Newspaper</v-col
                      >
                    </v-row>

                    <v-row class="align-center">
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        class="newslist"
                        v-for="item in listofInActiveNewspaper"
                        :key="item.id"
                      >
                        <v-row class="ma-0">
                          <v-col
                            cols="12"
                            class="border-1 border-accentxlight rounded"
                          >
                            <v-row class="base rounded">
                              <v-col cols="12" class="text-center">
                                <img :src="item.thumbnailURL" />
                              </v-col>
                              <v-col cols="12" class="pt-0">
                                <v-row
                                  no-gutters
                                  class="justify-center align-center"
                                >
                                  <v-col cols="auto" class="mr-2"
                                    ><v-btn
                                      text
                                      class="editbtn px-0 px-sm-3"
                                      color="accentlight"
                                      @click="OpenDialog('edit', item)"
                                    >
                                      <span
                                        class="
                                          material-icons-outlined
                                          lgFont
                                          mr-1
                                        "
                                        >edit</span
                                      >
                                      Edit</v-btn
                                    ></v-col
                                  >
                                  <v-col cols="auto" class="mr-2"
                                    ><v-btn
                                      text
                                      class="removebtn px-0 px-sm-3"
                                      color="accentlight"
                                      @click="confirmRemove(item.id)"
                                    >
                                      <span class="material-icons lgFont mr-1"
                                        >delete</span
                                      >
                                      Remove</v-btn
                                    ></v-col
                                  >
                                  <v-col cols="auto">
                                    <v-checkbox
                                      small
                                      hide-details
                                      v-model="item.isActive"
                                      class="mt-0 pt-0 customcheckbox"
                                      label="ACTIVE"
                                      @change="
                                        ChangeActiveStatus(
                                          item.id,
                                          item.isActive
                                        )
                                      "
                                    ></v-checkbox>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </vue-scroll>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog v-model="dialog" content-class="dashboardmodal" max-width="400">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col>{{ PopupHeading }}</v-col>
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="ClosePopup"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :loading="savingData"
                @click="SubmitForm"
                :disabled="!formValid || savingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form ref="modalForm" v-model="formValid">
            <v-row>
              <v-col cols="12">
                <div class="">
                  <div
                    class="imguploaderdiv profilephotodiv"
                    v-if="!imageUrl"
                    @click="Cropperdialog = true"
                  >
                    <span
                      class="
                        material-icons-outlined
                        accentlight--text
                        uploadicon
                      "
                    >
                      upload_file
                    </span>
                    <p class="accentdark--text smFont py-2 py-sm-2 mb-0">
                      Upload Thumbnail
                    </p>
                  </div>

                  <img
                    v-if="imageUrl"
                    class="impuploader newspaperupload"
                    @click="Cropperdialog = true"
                    :src="imageUrl"
                  />
                  <!-- <input
                    ref="fileuploadInput"
                    class="d-none"
                    type="file"
                    name="img"
                    accept="image/x-png,image/jpeg"
                    @change="uploadImage($event)"
                  /> -->
                </div>
              </v-col>
              <!-- <v-col cols="12">
                <input
                  ref="fileuploadInput"
                  type="file"
                  accept="image/*"
                  @change="uploadImage($event)"
                  id="file-input"
                /> </v-col
            > -->
            </v-row>
            <v-row
              ><v-col cols="12">
                <v-text-field
                  label="Newspaper Title"
                  outlined
                  dense
                  maxlength="20"
                  hide-details
                  v-model="NewspaperTitle"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field></v-col
            ></v-row>
            <v-row
              ><v-col cols="12">
                <v-text-field
                  label="Newspaper Link"
                  outlined
                  prepend-inner-icon="mdi-link"
                  dense
                  hide-details
                  v-model="NewspaperLink"
                  :rules="Linkrules"
                ></v-text-field></v-col
            ></v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right justify-end">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="ClosePopup"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :loading="savingData"
                @click="SubmitForm"
                :disabled="!formValid || savingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row>
            <v-col class="fw-500 mdFont accentdark--text">
              Are you sure you want to remove this item?
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="showRemoveConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="RemoveNewspaper(currentRemoveId)"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="Cropperdialog"
      content-class="imagecropermodal"
      max-width="360"
      style="height: 500px"
    >
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">Newspaper Image</v-col>
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="cancelCrop"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                :disabled="!imageuploadedforCropping"
                class="accent--text"
                small
                @click="SaveImageCrop"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <image-cropper
            v-on:imageUploaded="imageuploadedforCropping = true"
            ref="imagecropComp"
            :AspectRatioHeight="400"
            :AspectRatioWidth="600"
          />
        </v-card-text>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right justify-end">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="cancelCrop"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                :disabled="!imageuploadedforCropping"
                class="accent--text"
                small
                @click="SaveImageCrop"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";
import ImageCropper from "../../components/Popup/ImageCropper.vue";

export default {
  name: "NewspaperPage",
  created() {
    this.GetNewsPaperList();
  },
  components: {
    ImageCropper,
  },
  mixins: [apimethods, helpermethods],
  watch: {
    Cropperdialog: function (newValue, oldValue) {
      if (!newValue) this.cancelCrop();
      if (newValue && this.$refs.imagecropComp) {
        setTimeout(() => {
          this.$refs.imagecropComp.onButtonClick();
        }, 10);
      }
    },
  },
  data() {
    return {
      Cropperdialog: false,
      imageuploadedforCropping: false,
      showRemoveConfirmDialog: false,
      currentRemoveId: 0,
      formValid: false,
      dialog: false,
      listofActiveNewspaper: [],
      listofInActiveNewspaper: [],
      imageUrl: "",
      NewspaperTitle: "",
      NewspaperLink: "",
      openPopupType: "",
      EditedNewspaper: {},
      popupType: "",
      PopupHeading: "",
      savingData: false,
      Linkrules: [
        (value) => !!value || "Required.",
        (value) => this.isURL(value) || "URL is not valid",
      ],
    };
  },
  props: {
    msg: String,
  },
  methods: {
    cancelCrop() {
      this.$refs.imagecropComp.$data.img = null;
      this.$refs.imagecropComp.$refs.uploader.value = "";
      this.Cropperdialog = false;
      this.$data.imageuploadedforCropping = false;
    },
    SaveImageCrop() {
      if (this.$refs.imagecropComp.CroppedImage != undefined)
        this.imageUrl =
          this.$refs.imagecropComp.CroppedImage.toDataURL("image/jpg");
      this.Cropperdialog = false;
      this.$data.imageuploadedforCropping = false;
    },
    ClosePopup() {
      this.dialog = false;
    },
    isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },
    OpenDialog(type, item) {
      this.openPopupType = type;

      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetValidation();
      }
      // if (this.$refs.fileuploadInput != undefined)
      //   this.$refs.fileuploadInput.value = null;
      this.imageUrl = null;
      this.popupType = type;
      if (type == "create") {
        this.NewspaperTitle = "";
        this.NewspaperLink = "";
        this.PopupHeading = "Add Newspaper";
        this.imageUrl = "";
      } else {
        this.EditedNewspaper = item;
        this.NewspaperTitle = item.title;
        this.NewspaperLink = item.link;
        this.PopupHeading = "Edit Newspaper";
        this.imageUrl = item.thumbnailURL;
      }
      this.dialog = true;
    },

    SubmitForm() {
      this.savingData = true;
      if (this.popupType == "create") {
        this.AddNewsPaper(this.EditedNewspaper);
      } else {
        this.EditNewsPaper(this.EditedNewspaper);
      }
    },
    EditNewsPaper(item) {
      var self = this;
      var bodyFormData = new FormData();
      bodyFormData.append("id", item.id);
      bodyFormData.append("title", this.NewspaperTitle);
      bodyFormData.append("link", this.NewspaperLink);
      bodyFormData.append("thumbnailURL", item.thumbnailURL);
      //bodyFormData.append("thumbnailImage", item.thumbnailURL);

      bodyFormData.append("isActive", item.isActive);
      if (
        this.imageUrl &&
        this.imageUrl != "" &&
        !this.imageUrl.includes("digitaloceanspaces")
      ) {
        bodyFormData.append("UpdateThumbnailImage", true);
        var filename = this.generateUUID() + ".png";
        bodyFormData.append("name", filename);

        var blobBin = atob(this.imageUrl.split(",")[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        var file = new Blob([new Uint8Array(array)], { type: "image/png" });
        bodyFormData.append("file", file);
        bodyFormData.append("ThumbnailImageFileName", filename);
      } else {
        bodyFormData.append("UpdateThumbnailImage", false);
      }

      this.PostFormRequestWithAuthorization(
        "api/Newspaper/Edit",
        undefined,
        bodyFormData
      )
        .then((res) => {
          this.savingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Newspaper Updated Successfully",
              type: "success",
            });
            self.dialog = false;
            //alert("Newspaper Added");
            self.GetNewsPaperList();
          }
        })
        .catch((ex) => {
          console.log(ex);
          this.savingData = false;
        });
    },
    AddNewsPaper() {
      if (!this.imageUrl || this.imageUrl == "") {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Please select image",
          type: "error",
        });
        this.savingData = false;
        return;
      }

      var self = this;
      var bodyFormData = new FormData();
      bodyFormData.append("title", this.NewspaperTitle);
      bodyFormData.append("link", this.NewspaperLink);
      bodyFormData.append("thumbnailURL", "");
      bodyFormData.append("thumbnailImage", "");
      bodyFormData.append("isActive", true);
      bodyFormData.append("UpdateThumbnailImage", true);

      var filename = this.generateUUID() + ".png";
      var blobBin = atob(this.imageUrl.split(",")[1]);
      var array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      var file = new Blob([new Uint8Array(array)], { type: "image/png" });
      bodyFormData.append("file", file);
      bodyFormData.append("name", filename);
      bodyFormData.append("ThumbnailImageFileName", filename);

      this.$store.commit("showLoadingModal");

      this.PostFormRequestWithAuthorization(
        "api/Newspaper/Add",
        undefined,
        bodyFormData
      )
        .then((res) => {
          this.savingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Newspaper Added Successfully",
              type: "success",
            });
            self.dialog = false;
            //alert("Newspaper Added");
            self.GetNewsPaperList();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");

          this.savingData = false;
          console.log(ex);
        });
    },
    ChangeActiveStatus(newspaperId, active) {
      var self = this;
      this.$store.commit("showLoadingModal");
      this.GetRequestWithAuthorization("api/Newspaper/MarkActiveUnActive", [
        {
          Key: "newsPaperId",
          Value: newspaperId,
        },
        {
          Key: "active",
          Value: active,
        },
      ])
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Status updated Successfully",
              type: "success",
            });
            self.dialog = false;
            //alert("Status Changed");
            self.GetNewsPaperList();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");

          console.log(ex);
        });
    },
    confirmRemove(newspaperId) {
      this.$data.showRemoveConfirmDialog = true;
      this.$data.currentRemoveId = newspaperId;
    },
    RemoveNewspaper(newspaperId) {
      this.$data.showRemoveConfirmDialog = false;
      var self = this;
      this.$store.commit("showLoadingModal");

      this.GetRequestWithAuthorization("api/Newspaper/Remove", [
        {
          Key: "newsPaperId",
          Value: newspaperId,
        },
      ])
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Newspaper removed Successfully",
              type: "success",
            });
            //alert("Newspaper removed");
            self.dialog = false;
            self.GetNewsPaperList();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          console.log(ex);
        });
    },
    GetNewsPaperList() {
      try {
        var self = this;
        this.$store.commit("showLoadingModal");
        var request = this.GetRequestWithAuthorization(
          "api/Newspaper/Get",
          undefined
        );
        if (request != undefined) {
          request.then((res) => {
            this.$store.commit("hideLoadingModal");

            if (
              res != undefined &&
              res.data != undefined &&
              res.data.data != undefined
            ) {
              res.data.data.forEach((element) => {
                element.thumbnailURL =
                  element.thumbnailURL + "?" + performance.now();
              });
              self.listofActiveNewspaper = res.data.data.filter(
                (x) => x.isActive == true
              );
              self.listofInActiveNewspaper = res.data.data.filter(
                (x) => x.isActive == false
              );
            }
          });
        }

        request.catch((ex) => {});
      } catch (exa) {
        this.$store.commit("hideLoadingModal");
        console.log(exa);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
