<template>
  <v-col cols="12" sm="12" lg="12" class="pr-lg-1">
    <v-card class="fill-height">
      <v-card-title class="borderBottom-1 border-accentxlight"
        >News & Updates</v-card-title
      >
      <v-card-text class="py-2 pr-1 newupdatediv">
        <vue-scroll :ops="ops">
          <div class="scrolllimit">
            <v-row
              no-gutters
              class="py-1 pr-4 mb-2 newsupdate borderBottom-1 border-base"
              v-for="(item, index) in listofFilteredNews"
              :key="index"
            >
              <v-col cols="auto">
                <img :src="item.images[0].url" />
              </v-col>
              <v-col class="pl-3 min-wd-0">
                <p class="fw-500 xsFont mb-0 text-truncate">
                  {{ item.title }}
                </p>
                <p class="xxxsFont accentlight--text mb-0 py-1">
                  {{ item.category.title }} | Dated: {{ item.publishedOn }}
                </p>
                <v-col cols="12" class="fw-400 xsFont p-relative pa-0">
                  <div class="newsupdatecontent ceomessage seemorediv">
                    <div v-html="item.description"></div>
                    <a
                      @click="previewNews(JSON.parse(JSON.stringify(item)))"
                      class="readmoredot d-none"
                      >Read More...</a
                    >
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </div></vue-scroll
        >
        <v-row
          no-gutters
          class="readmorebtndiv pr-4"
          v-if="listofNews.length > 4"
        >
          <v-col>
            <v-btn
              @click.stop="allNewsDialog = true"
              block
              class="white--text"
              color="accentlight"
              >More News & Updates</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="allNewsDialog" max-width="750" scrollable>
      <v-card class="mb-0">
        <v-card-title class="pa-3">New & Updates</v-card-title>
        <vue-scroll>
          <v-card-text class="pa-0 accentdark--text">
            <v-row class="ma-0 pa-3">
              <v-col cols="12" class="pt-0">
                <v-row
                  no-gutters
                  class="py-1 pr-4 mb-2 newsupdate borderBottom-1 border-base"
                  v-for="(item, index) in listofNews"
                  :key="index"
                >
                  <v-col cols="auto">
                    <img :src="item.images[0].url" />
                  </v-col>
                  <v-col class="pl-3 min-wd-0">
                    <p class="fw-500 xsFont mb-0 text-truncate">
                      {{ item.title }}
                    </p>
                    <p class="xxxsFont accentlight--text mb-0 py-2">
                      {{ item.category.title }} | Dated: {{ item.publishedOn }}
                    </p>
                    <v-col cols="12" class="fw-400 xsFont p-relative pa-0">
                      <div class="newsupdatecontent ceomessage seemorediv">
                        <div v-html="item.description"></div>
                        <a
                          @click="previewNews(JSON.parse(JSON.stringify(item)))"
                          class="readmoredot d-none"
                          >Read More...</a
                        >
                      </div>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>
    <v-dialog v-model="previewNewsDialog" max-width="750" scrollable>
      <v-card class="mb-0" v-if="previewNewsDialog">
        <vue-scroll>
          <v-card-text class="pa-0 accentdark--text">
            <v-row class="mx-0">
              <v-col class="px-0">
                <img
                  :src="newsupdateModel.images[0].url"
                  class="newspreviewheader"
                />
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-3">
              <v-col cols="12" class="pt-0">
                <v-row no-gutters class="mt-3">
                  <v-col cols="12">
                    <h2>{{ newsupdateModel.title }}</h2>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="mt-3">
                    <p>
                      {{ newsupdateModel.category.title }} | Dated:
                      {{ newsupdateModel.publishedOn }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="xsFont"
                    v-html="newsupdateModel.description"
                  >
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" md="8" class="mx-auto">
                    <v-slide-group
                      v-model="model"
                      class="px-0 py-1 newsslider newsmodalitems"
                      show-arrows
                    >
                      <v-slide-item
                        class="px-2"
                        v-for="(item, i) in newsupdateModel.images"
                        :key="i"
                      >
                        <div class="ceomodalitems">
                          <iframe
                            v-if="item.assetType == 'drive'"
                            :src="item.url"
                            width="200px"
                            height="180px"
                          ></iframe>
                          <iframe
                            v-if="item.assetType == 'youtube'"
                            width="200px"
                            height="180px"
                            :src="item.url"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>

                          <video-player
                            v-if="item.assetType == 'video'"
                            class="video-player-box"
                            ref="videoPlayerPopup"
                            :options="item.playerPopupOptions"
                          />
                          <img
                            v-if="item.assetType == 'Image'"
                            :src="item.url"
                          />
                        </div>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>
  </v-col>
</template>
     <script>
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";
import { videoPlayer } from "vue-video-player";

export default {
  mixins: [apimethods, helpermethods],
  components: { videoPlayer },

  data() {
    return {
      ops: {
        scrollPanel: {
          scrollingX: false,
        },
      },
      allNewsDialog: false,
      model: null,
      previewNewsDialog: false,
      listofNews: [],
      newsupdateModel: {},
      listofFilteredNews: [],
    };
  },
  name: "NewsAndUpdates",
  mounted() {
    this.GetActiveNews();
  },
  methods: {
    previewNews(news) {
      this.newsupdateModel = news;
      this.previewNewsDialog = true;
    },
    GetActiveNews() {
      this.$store.commit("showLoadingModal");
      var self = this;
      self.listofNews = [];

      this.GetRequestWithAuthorization("api/News/Get", [
        {
          Key: "activeOnly",
          Value: true,
        },
      ])
        .then((res) => {
          this.$store.commit("hideLoadingModal");

          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.listofNews = JSON.parse(JSON.stringify(res.data.data));
            self.listofFilteredNews = self.listofNews.slice(0, 4);

            if (self.listofNews) {
              self.listofNews.forEach((el) => {
                el.images.forEach((element) => {
                  if (element.assetType == "video") {
                    element.playerOptions = {
                      // videojs options
                      muted: false,
                      language: "en",
                      width: "335px",
                      height: "200px",
                      sources: [
                        {
                          type: "video/mp4",
                          src: element.url,
                        },
                      ],
                    };
                    element.playerPopupOptions = {
                      // videojs options
                      muted: false,
                      language: "en",
                      width: "250px",
                      height: "180px",
                      sources: [
                        {
                          type: "video/mp4",
                          src: element.url,
                        },
                      ],
                    };
                  }
                });
              });
            }
            setTimeout(() => {
              self.allocateseemorediv(null);
            }, 10);
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
