<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col class="lgFont accentdark--text fw-700">
              News & Updates
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="accent white--text"
                @click.stop="showNewsPopup('Add')"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                >Add News</v-btn
              >
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-3">
            <v-col>
              <v-chip-group
                mandatory
                show-arrows
                class="accentdark--text xsFont"
                active-class="accent--text"
              >
                <v-chip
                  outlined
                  @click.stop="openAddCategoryDialog()"
                  active-class="accent--text"
                  class="accentdark--text xsFont addcatchip"
                >
                  <span class="material-icons-outlined mr-1 smFont"
                    >add_circle_outline</span
                  >
                  Add Category
                </v-chip>
                <v-chip
                  outlined
                  @click.stop="filterNewsPerCategory(null)"
                  active-class="accent--text"
                  class="accentdark--text xsFont othercatchip"
                >
                  All
                </v-chip>
                <v-chip
                  v-for="(item, index) in listofCategories"
                  :key="index"
                  outlined
                  active-class="accent--text accent"
                  class="accentdark--text xsFont othercatchip"
                  @click="filterNewsPerCategory(item.id)"
                >
                  {{ item.title }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>

          <v-row class="fill-height p-relative mt-0 mb-1">
            <v-col>
              <v-row class="fill-height p-absolute wd-100">
                <vue-scroll :ops="ops">
                  <v-col cols="12">
                    <v-tabs
                      show-arrows
                      dense
                      class="browsetab generaltab"
                      height="45px"
                    >
                      <v-tab active-class="base accentdark--text">All</v-tab>
                      <v-tab active-class="base accentdark--text">Active</v-tab>
                      <v-tab active-class="base accentdark--text"
                        >Inactive</v-tab
                      >

                      <v-tab-item class="pt-3 pl-3 pr-1">
                        <v-row
                          class="
                            borderBottom-1
                            border-secondary
                            mb-2
                            mr-0
                            xsFont
                          "
                          v-for="(item, index) in listofFilteredNews"
                          :key="index"
                        >
                          <v-col class="pl-0">
                            <v-row class="align-center">
                              <v-col class="accentdark--text lgFont fw-500">{{
                                item.title
                              }}</v-col>
                              <v-col cols="12" class="pt-0 d-sm-none">
                                <img
                                  :src="item.images[0].url"
                                  class="newsimg rounded"
                                />
                              </v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col
                                class="accentlight--text fw-500 pt-0 xxsFont"
                              >
                                Published On: {{ item.publishedOn }}
                                <span class="px-4">|</span>Subcategory:
                                {{ item.category.title }}
                              </v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col class="accentdark--text pt-0 xxsFont">
                                <div class="ceomessage seemorediv">
                                  <div v-html="item.description"></div>
                                  <a
                                    @click="
                                      previewNews(
                                        JSON.parse(JSON.stringify(item))
                                      )
                                    "
                                    class="readmoredot d-none"
                                    >Read More...</a
                                  >
                                </div>
                              </v-col>
                            </v-row>

                            <v-row class="mt-0">
                              <v-col cols="auto" class="text-right pt-0">
                                <v-btn
                                  text
                                  color="accentlight"
                                  class="mr-1 px-0 pr-sm-3 editbtn"
                                  @click="
                                    previewNews(
                                      JSON.parse(JSON.stringify(item))
                                    )
                                  "
                                >
                                  <span
                                    class="material-icons-outlined lgFont mr-1"
                                    >visibility</span
                                  >Preview</v-btn
                                >

                                <v-btn
                                  text
                                  color="accentlight"
                                  class="editbtn mr-1 px-0 px-sm-3"
                                  @click="
                                    showNewsPopup(
                                      'Edit',
                                      JSON.parse(JSON.stringify(item))
                                    )
                                  "
                                >
                                  <span
                                    class="material-icons-outlined lgFont mr-1"
                                    >edit</span
                                  >Edit</v-btn
                                >
                                <v-btn
                                  text
                                  color="accentlight"
                                  class="mr-2 px-0 px-sm-3 removebtn"
                                  @click="confirmRemove(item.id)"
                                >
                                  <span class="material-icons lgFont mr-1"
                                    >delete</span
                                  >Remove</v-btn
                                >
                                <div class="d-inline-block">
                                  <v-switch
                                    class="mt-0 pt-0 activeswitch"
                                    hide-details
                                    dense
                                    color="accent"
                                    v-model="item.isActive"
                                    :label="
                                      item.isActive ? 'ACTIVE' : 'INACTIVE'
                                    "
                                    @change="
                                      ChangeActiveUnActiveStatus(
                                        item.id,
                                        item.isActive
                                      )
                                    "
                                  ></v-switch>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="auto" class="d-none d-sm-block">
                            <img
                              :src="item.images[0].url"
                              class="newsimg rounded"
                            />
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item class="pt-3 pl-3 pr-1">
                        <v-row
                          class="
                            borderBottom-1
                            borderTop-1
                            border-secondary
                            my-2
                            mr-0
                            xsFont
                          "
                          v-for="(item, index) in listofActiveNews"
                          :key="index"
                        >
                          <v-col class="pl-0">
                            <v-row class="align-center">
                              <v-col class="accentdark--text lgFont fw-500">{{
                                item.title
                              }}</v-col>
                              <v-col cols="12" class="pt-0 d-sm-none">
                                <img
                                  :src="item.images[0].url"
                                  class="newsimg rounded"
                                />
                              </v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col
                                class="accentlight--text fw-500 pt-0 xxsFont"
                              >
                                Published On: {{ item.publishedOn }}
                                <span class="px-4">|</span>Subcategory:
                                {{ item.category.title }}
                              </v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col class="accentdark--text pt-0 xxsFont">
                                <div class="ceomessage seemorediv">
                                  <div v-html="item.description"></div>
                                  <a
                                    @click="
                                      previewNews(
                                        JSON.parse(JSON.stringify(item))
                                      )
                                    "
                                    class="readmoredot d-none"
                                    >Read More...</a
                                  >
                                </div>
                              </v-col>
                            </v-row>

                            <v-row class="mt-0">
                              <v-col cols="auto" class="text-right pt-0">
                                <v-btn
                                  text
                                  color="accentlight"
                                  class="mr-1 px-0 pr-sm-3 editbtn"
                                  @click="
                                    previewNews(
                                      JSON.parse(JSON.stringify(item))
                                    )
                                  "
                                >
                                  <span
                                    class="material-icons-outlined lgFont mr-1"
                                    >visibility</span
                                  >Preview</v-btn
                                >

                                <v-btn
                                  text
                                  color="accentlight"
                                  class="editbtn mr-1 px-0 px-sm-3"
                                  @click="
                                    showNewsPopup(
                                      'Edit',
                                      JSON.parse(JSON.stringify(item))
                                    )
                                  "
                                >
                                  <span
                                    class="material-icons-outlined lgFont mr-1"
                                    >edit</span
                                  >Edit</v-btn
                                >
                                <v-btn
                                  text
                                  color="accentlight"
                                  class="mr-2 px-0 px-sm-3 removebtn"
                                  @click="confirmRemove(item.id)"
                                >
                                  <span class="material-icons lgFont mr-1"
                                    >delete</span
                                  >Remove</v-btn
                                >
                                <div class="d-inline-block">
                                  <v-switch
                                    class="mt-0 pt-0 activeswitch"
                                    hide-details
                                    dense
                                    color="accent"
                                    v-model="item.isActive"
                                    :label="
                                      item.isActive ? 'ACTIVE' : 'INACTIVE'
                                    "
                                    @change="
                                      ChangeActiveUnActiveStatus(
                                        item.id,
                                        item.isActive
                                      )
                                    "
                                  ></v-switch>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="auto" class="d-none d-sm-block">
                            <img
                              :src="item.images[0].url"
                              class="newsimg rounded"
                            />
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item class="pt-3 pl-3 pr-1">
                        <v-row
                          class="
                            borderBottom-1
                            borderTop-1
                            border-secondary
                            my-2
                            mr-0
                            xsFont
                          "
                          v-for="(item, index) in listofInActiveNews"
                          :key="index"
                        >
                          <v-col class="pl-0">
                            <v-row class="align-center">
                              <v-col class="accentdark--text lgFont fw-500">{{
                                item.title
                              }}</v-col>
                              <v-col cols="12" class="pt-0 d-sm-none">
                                <img
                                  :src="item.images[0].url"
                                  class="newsimg rounded"
                                />
                              </v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col
                                class="accentlight--text fw-500 pt-0 xxsFont"
                              >
                                Published On: {{ item.publishedOn }}
                                <span class="px-4">|</span>Subcategory:
                                {{ item.category.title }}
                              </v-col>
                            </v-row>
                            <v-row class="mt-2 align-center">
                              <v-col class="accentdark--text pt-0 xxsFont">
                                <div class="ceomessage seemorediv">
                                  <div v-html="item.description"></div>
                                  <a
                                    @click="
                                      previewNews(
                                        JSON.parse(JSON.stringify(item))
                                      )
                                    "
                                    class="readmoredot d-none"
                                    >Read More...</a
                                  >
                                </div>
                              </v-col>
                            </v-row>

                            <v-row class="mt-0">
                              <v-col cols="auto" class="text-right pt-0">
                                <v-btn
                                  text
                                  color="accentlight"
                                  class="mr-1 px-0 pr-sm-3 editbtn"
                                  @click="
                                    previewNews(
                                      JSON.parse(JSON.stringify(item))
                                    )
                                  "
                                >
                                  <span
                                    class="material-icons-outlined lgFont mr-1"
                                    >visibility</span
                                  >Preview</v-btn
                                >

                                <v-btn
                                  text
                                  color="accentlight"
                                  class="editbtn mr-1 px-0 px-sm-3"
                                  @click="
                                    showNewsPopup(
                                      'Edit',
                                      JSON.parse(JSON.stringify(item))
                                    )
                                  "
                                >
                                  <span
                                    class="material-icons-outlined lgFont mr-1"
                                    >edit</span
                                  >Edit</v-btn
                                >
                                <v-btn
                                  text
                                  color="accentlight"
                                  class="mr-2 px-0 px-sm-3 removebtn"
                                  @click="confirmRemove(item.id)"
                                >
                                  <span class="material-icons lgFont mr-1"
                                    >delete</span
                                  >Remove</v-btn
                                >
                                <div class="d-inline-block">
                                  <v-switch
                                    class="mt-0 pt-0 activeswitch"
                                    hide-details
                                    dense
                                    color="accent"
                                    v-model="item.isActive"
                                    :label="
                                      item.isActive ? 'ACTIVE' : 'INACTIVE'
                                    "
                                    @change="
                                      ChangeActiveUnActiveStatus(
                                        item.id,
                                        item.isActive
                                      )
                                    "
                                  ></v-switch>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="auto" class="d-none d-sm-block">
                            <img
                              :src="item.images[0].url"
                              class="newsimg rounded"
                            />
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </v-tabs>
                  </v-col> </vue-scroll></v-row
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="newsupdateDialog" scrollable max-width="800px">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text"
              >{{ newspopuptype }} News & Update</v-col
            >
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="newsupdateDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :disabled="
                  !this.newsupdateModel.title ||
                  this.newsupdateModel.title.trim() == '' ||
                  !this.newsupdateModel.description ||
                  this.newsupdateModel.description.trim() == '' ||
                  !this.newsFormValid ||
                  !this.newsupdateModel.images ||
                  this.newsupdateModel.images.length == 0
                "
                :loading="isSavingNews"
                @click="saveNews"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll :ops="ops">
          <v-card-text>
            <v-form
              ref="newsupdateform"
              class="wd-100 py-3"
              v-model="newsFormValid"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Heading"
                    :rules="[(v) => !!v.trim() || 'Heading is required']"
                    outlined
                    dense
                    v-model="newsupdateModel.title"
                    maxlength="200"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="Sub Category"
                    outlined
                    :rules="[(v) => !!v || 'Cateogry is required']"
                    :items="listofCategories"
                    item-text="title"
                    item-value="id"
                    v-model="newsupdateModel.categoryId"
                    dense
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="document-editor__toolbar"></div>
                  <div class="document-editor__editable-container">
                    <ckeditor
                      style="height: 180px !important; background-color: white"
                      :editor="editor"
                      :config="editorConfig"
                      :rules="[(v) => !!v.trim() || 'Heading is required']"
                      v-model="newsupdateModel.description"
                      @ready="onReady"
                    ></ckeditor>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-tabs
                    show-arrows
                    dense
                    class="browsetab"
                    height="40px"
                    v-model="photovideotab"
                  >
                    <v-tab active-class="base notransform accentdark--text"
                      >Upload a Photo</v-tab
                    >
                    <v-tab
                      class="notransform"
                      active-class="base  accentdark--text"
                      >Link a Video</v-tab
                    >

                    <v-tab-item class="pt-3 pl-3 pr-1">
                      <v-row>
                        <v-col cols="auto" class="pl-0" >
                           <div class="imguploaderdiv uploadmultiimg" @click="onButtonClick">
                                 <span class="material-icons-outlined accentlight--text uploadicon"> upload_file </span>
                                 <p class="accentdark--text uploadtext mb-0"> Upload a Photo </p>
                                </div>
                          <!-- <v-btn
                            outlined
                            class="
                              accentlight--text
                              text-notransform text-notransform
                              px-8
                              py-4
                            "
                            small
                            @click="onButtonClick"
                            >Browse</v-btn
                          > -->

                          <input
                            ref="fileuploadInput"
                            class="d-none"
                            type="file"
                            name="img"
                            multiple
                            accept="image/x-png,image/jpeg"
                            @change="uploadImage($event)"
                          />
                        </v-col>
                      
                        <v-col class="pl-0">
                          <img
                            v-for="(item, index) in newsupdateModel.images"
                            :key="index"
                            class="imgpreview"
                            :src="item.url"
                          />
                        </v-col>
                      </v-row> </v-tab-item
                    ><v-tab-item class="pt-3 pl-3 pr-1">
                      <v-text-field
                        label="URL"
                        outlined
                        prepend-inner-icon="mdi-link"
                        dense
                        v-model="newsupdateModel.videoLink"
                        hide-details
                        :rules="NullableLinkrules"
                      ></v-text-field>
                    </v-tab-item>
                  </v-tabs>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </vue-scroll>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right pt-5 justify-end">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="newsupdateDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :disabled="
                  !this.newsupdateModel.title ||
                  this.newsupdateModel.title.trim() == '' ||
                  !this.newsupdateModel.description ||
                  this.newsupdateModel.description.trim() == '' ||
                  !this.newsFormValid ||
                  !this.newsupdateModel.images ||
                  this.newsupdateModel.images.length == 0
                "
                :loading="isSavingNews"
                @click="saveNews"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewNewsDialog" max-width="750" scrollable>
      <v-card class="mb-0" v-if="previewNewsDialog">
        <vue-scroll :ops="ops">
          <v-card-text class="pa-0 accentdark--text">
            <v-row class="mx-0">
              <v-col class="px-0">
                <img
                  :src="newsupdateModel.images[0].url"
                  class="newspreviewheader"
                />
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-3">
              <v-col cols="12" class="pt-0">
                <v-row no-gutters class="mt-3">
                  <v-col cols="12">
                    <h2>{{ newsupdateModel.title }}</h2>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="mt-3">
                    <p>
                      {{ newsupdateModel.category.title }} | Dated:
                      {{ newsupdateModel.publishedOn }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="xsFont"
                    v-html="newsupdateModel.description"
                  >
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" md="10" class="mx-auto">
                    <v-slide-group
                      v-model="model"
                      class="px-0 py-1 newsslider newsmodalitems"
                      show-arrows
                    >
                      <v-slide-item
                        class="px-2"
                        v-for="(item, index) in newsupdateModel.images"
                        :key="index"
                      >
                        <div class="ceomodalitems">
                          <iframe
                            v-if="item.assetType == 'drive'"
                            :src="item.url"
                            width="200px"
                            height="180px"
                          ></iframe>
                          <iframe
                            v-if="item.assetType == 'youtube'"
                            width="200px"
                            height="180px"
                            :src="item.url"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>

                          <video-player
                            v-if="item.assetType == 'video'"
                            class="video-player-box"
                            ref="videoPlayerPopup"
                            :options="item.playerPopupOptions"
                          />
                          <img
                            v-if="item.assetType == 'Image'"
                            :src="item.url"
                          />
                        </div>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addcategoryDialog" scrollable max-width="450px">
      <v-card class="mb-0">
        <v-card-title class="accentdark--text">
          <v-row
            class="align-center borderBottom-1 border-accentxlight mx-n5 px-2"
          >
            <v-col class="accentdark--text">Add Category</v-col>
            <v-col cols="auto"> </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll :ops="ops">
          <v-card-text class="pt-3">
            <v-form ref="categoryForm" class="wd-100 py-3" v-model="formValid">
              <v-row class="align-center">
                <v-col>
                  <v-text-field
                    label="Enter New Category"
                    outlined
                    maxlength="50"
                    :rules="[(v) => !!v.trim() || 'Title is required']"
                    dense
                    hide-details
                    v-model="categoryTitle"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" class="pl-0">
                  <v-btn
                    outlined
                    :disabled="!formValid"
                    :loading="isSavingCategory"
                    class="accent--text fw-400 px-4 mdFont height-40"
                    small
                    @click="SaveCategory"
                    >Add</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
            <v-row no-gutters class="xsFont mt-0 accentlight--text">
              <v-col cols="12">
                <v-row
                  v-for="(item, index) in listofCategories"
                  :key="index"
                  no-gutters
                  class="
                    border-1 border-secondary
                    rounded
                    pl-3
                    py-3
                    pr-0
                    mt-2
                    align-center
                  "
                >
                  <v-col>
                    Category Title
                    <div class="accentdark--text text-truncate fw-500">
                      {{ item.title }}
                    </div>
                  </v-col>
                  <v-col cols="auto" class="pr-0">
                    <v-btn
                      text
                      color="accentlight"
                      small
                      class="mr-1 editbtn"
                      @click="showEditCategoryDialog(item.id, item.title)"
                    >
                      <span class="material-icons-outlined lgFont mr-1"
                        >edit</span
                      >Edit</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editcategoryDialog" scrollable max-width="450px">
      <v-card class="mb-0">
        <v-card-title class="accentdark--text">
          <v-row
            class="align-center borderBottom-1 border-accentxlight mx-n5 px-2"
          >
            <v-col class="accentdark--text">Edit Category</v-col>
            <v-col cols="auto">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="editcategoryDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :disabled="!editformValid"
                :loading="isSavingCategory"
                @click="SaveCategory(false)"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="editcategoryForm" v-model="editformValid">
            <v-row class="align-center">
              <v-col>
                <v-text-field
                  label="Category Title"
                  outlined
                  maxlength="50"
                  :rules="[(v) => !!v.trim() || 'Title is required']"
                  dense
                  hide-details
                  v-model="categoryTitle"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row>
            <v-col class="fw-500 mdFont accentdark--text">
              Are you sure you want to remove this?
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                flat
                class="accentlight--text mr-2"
                small
                @click="showRemoveConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                flat
                class="accent--text"
                small
                @click="RemoveNews(currentRemoveId)"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default {
  mixins: [apimethods, helpermethods],
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      ops: {
        scrollPanel: {
          scrollingX: false,
        },
      },
      editor: DocumentEditor,
      newsupdateDialog: false,
      addcategoryDialog: false,
      editcategoryDialog: false,
      previewNewsDialog: false,
      newsFormValid: false,
      isSavingNews: false,
      editformValid: false,
      isSavingCategory: false,
      categoryTitle: "",
      currentCategoryId: 0,
      formValid: false,
      listofCategories: [],
      SelectedFiles: [],
      listofNews: [],
      photovideotab: 0,
      listofFilteredNews: [],
      currentRemoveId: 0,
      newspopuptype: "",
      showRemoveConfirmDialog: false,
      newsupdateModel: { images: [], VideoLink: "" },
      editorConfig: {
        toolbar: {
          items: [
            "bold",
            "italic",
            "undo",
            "redo",
            "alignment:left",
            "alignment:right",
            "alignment:center",
            "alignment:justify",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "indent",
            "outdent",
            "numberedList",
          ],
          shouldNotGroupWhenFull: true,
        },
      },
    };
  },
  name: "NewsUpdatePage",
  props: {
    msg: String,
  },
  mounted() {
    this.GetCategoriesAndNews();
  },
  computed: {
    listofActiveNews() {
      return this.listofFilteredNews.filter((x) => x.isActive == true);
    },
    listofInActiveNews() {
      return this.listofFilteredNews.filter((x) => x.isActive == false);
    },
  },
  methods: {
    previewNews(news) {
      this.newsupdateModel = news;
      this.newsupdateModel.images.forEach((element) => {
        if (element.assetType == "video") {
          element.playerOptions = {
            // videojs options
            muted: false,
            language: "en",
            width: "335px",
            height: "200px",
            sources: [
              {
                type: "video/mp4",
                src: element.url,
              },
            ],
          };
          element.playerPopupOptions = {
            // videojs options
            muted: false,
            language: "en",
            width: "250px",
            height: "180px",
            sources: [
              {
                type: "video/mp4",
                src: element.url,
              },
            ],
          };
        }
      });
      this.previewNewsDialog = true;
    },
    filterNewsPerCategory(categoryId) {
      if (categoryId == null) {
        this.listofFilteredNews = this.listofNews;
        return;
      }

      this.listofFilteredNews = this.listofNews.filter(
        (x) => x.category.id == categoryId
      );
    },
    onButtonClick() {
      this.$refs.fileuploadInput.click();
    },
    uploadImage(event) {
      if (event.target.files != null && event.target.files.length > 0) {
        var arrayFile = Array.from(event.target.files);

        if (arrayFile.length > 5) {
          this.$notify({
            group: "notification",
            title: "Error",
            text: "You cannot select more than 5 images",
            type: "error",
          });
          return;
        }

        this.newsupdateModel.images = [];

        arrayFile = arrayFile.filter((x) => this.isFileImage(x));
        arrayFile.forEach((element) => {
          var imageUrlLocal = URL.createObjectURL(element);
          this.newsupdateModel.images.push({
            url: imageUrlLocal,
            File: element,
          });
        });
      }
    },
    showNewsPopup(
      popupType = "Add",
      editModel = { images: [], VideoLink: "" }
    ) {
      this.newspopuptype = popupType;
      this.newsupdateModel = editModel;
      if (popupType == "Edit") {
        this.newsupdateModel.images = this.newsupdateModel.images.filter(
          (x) => x.assetType == "Image"
        );
      }

      this.newsupdateDialog = true;
      this.photovideotab = 0;
      this.$refs.newsupdateform.resetValidation();
    },
    onReady(editor) {
      setTimeout(() => {
        const toolbarContainer = document.querySelector(
          ".document-editor__toolbar"
        );
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
        var pictureUploadbtn = toolbarContainer.getElementsByClassName(
          "ck-file-dialog-button"
        );
        if (pictureUploadbtn != undefined && pictureUploadbtn.length > 0)
          pictureUploadbtn[0].classList.add("d-none");
      }, 200);
    },
    showEditCategoryDialog(id, title) {
      this.currentCategoryId = id;
      this.categoryTitle = title;
      this.addcategoryDialog = false;
      this.editcategoryDialog = true;
      this.$refs.editcategoryForm.resetValidation();
    },
    openAddCategoryDialog() {
      this.currentCategoryId = 0;
      this.categoryTitle = "";
      this.addcategoryDialog = true;
      this.$refs.categoryForm.resetValidation();
    },
    GetCategoriesAndNews() {
      this.$store.commit("showLoadingModal");
      var self = this;
      var lstPromises = [];
      self.listofCategories = [];
      self.listofNews = [];
      self.listofFilteredNews = [];
      var categoriesProm = this.GetRequestWithAuthorization(
        "api/News/GetNewsCategories",
        undefined
      )
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.listofCategories = JSON.parse(JSON.stringify(res.data.data));
          }
        })
        .catch((ex) => {});
      lstPromises.push(categoriesProm);

      var newsProm = this.GetRequestWithAuthorization("api/News/Get", undefined)
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            res.data.data.forEach((element) => {
              element.images.forEach((image) => {
                if (image.assetType == "Image") {
                  image.url = image.url + "?" + performance.now();
                }
              });
            });

            self.listofNews = JSON.parse(JSON.stringify(res.data.data));
            self.listofFilteredNews = JSON.parse(JSON.stringify(res.data.data));
            setTimeout(() => {
              self.allocateseemorediv(null);
            }, 10);
          }
        })
        .catch((ex) => {});
      lstPromises.push(newsProm);

      Promise.all(lstPromises).then((values) => {
        this.$store.commit("hideLoadingModal");
      });
    },
    SaveCategory(isAdd = true) {
      this.$store.commit("showLoadingModal");
      this.isSavingCategory = true;
      var self = this;
      var bodyFormData = {};
      bodyFormData.title = this.categoryTitle;
      var url = "api/News/AddNewsCategory";
      var successmsg = "Category Added Successfully";
      if (!isAdd) {
        bodyFormData.id = this.currentCategoryId;
        url = "api/News/EditNewsCategory";
        successmsg = "Category Updated Successfully";
      }
      var elementJson = JSON.stringify(bodyFormData);
      this.PostRequestWithAuthorization(url, undefined, elementJson)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingCategory = false;
          if (res != undefined && res.data != undefined) {
            if (res.data.data == true) {
              self.$notify({
                group: "notification",
                title: "Success",
                text: successmsg,
                type: "success",
              });
              self.GetCategoriesAndNews();
              self.addcategoryDialog = false;
              self.editcategoryDialog = false;
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingCategory = false;
          console.log(ex);
        });
    },
    saveNews() {
      if (
        this.newsupdateModel.images == undefined ||
        this.newsupdateModel.images.length == 0
      ) {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Please upload image(s)",
          type: "error",
        });
        this.isSavingNews = false;
        return;
      }
      if (
        this.newsupdateModel.description == undefined ||
        this.newsupdateModel.description == ""
      ) {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Please enter some description",
          type: "error",
        });
        this.isSavingNews = false;
        return;
      }

      this.isSavingNews = true;

      var self = this;
      var bodyFormData = new FormData();
      if (this.newspopuptype == "Edit") {
        bodyFormData.append("id", this.newsupdateModel.id);
      }
      bodyFormData.append("title", this.newsupdateModel.title);
      if (
        this.newsupdateModel.videoLink == null ||
        this.newsupdateModel.videoLink == undefined ||
        !this.newsupdateModel.videoLink
      ) {
        this.newsupdateModel.videoLink = "";
      }
      bodyFormData.append("videoLink", this.newsupdateModel.videoLink);
      bodyFormData.append("description", this.newsupdateModel.description);
      bodyFormData.append("categoryId", this.newsupdateModel.categoryId);
      bodyFormData.append("isActive", true);
      this.newsupdateModel.images.forEach((element) => {
        if (element.File != null && element.File != undefined && element.File) {
          bodyFormData.append("file", element.File);
        }
      });

      this.$store.commit("showLoadingModal");

      var url = "api/News/" + self.newspopuptype;
      this.PostFormRequestWithAuthorization(url, undefined, bodyFormData)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingNews = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            if (self.newspopuptype == "Edit") {
              self.$notify({
                group: "notification",
                title: "Message",
                text: "News Updated Successfully",
                type: "success",
              });
            } else {
              self.$notify({
                group: "notification",
                title: "Message",
                text: "News Added Successfully",
                type: "success",
              });
            }

            self.newsupdateDialog = false;
            self.GetCategoriesAndNews();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.savingData = false;
          console.log(ex);
        });
    },
    confirmRemove(newsid) {
      this.$data.showRemoveConfirmDialog = true;
      this.$data.currentRemoveId = newsid;
    },
    RemoveNews(newsId) {
      this.$data.showRemoveConfirmDialog = false;
      var self = this;
      this.$store.commit("showLoadingModal");
      this.GetRequestWithAuthorization("api/News/Remove", [
        {
          Key: "newsId",
          Value: newsId,
        },
      ])
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "News Removed Successfully",
              type: "success",
            });
            self.dialog = false;
            self.GetCategoriesAndNews();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          console.log(ex);
        });
    },
    ChangeActiveUnActiveStatus(newsId, activeunactive) {
      var self = this;
      this.$store.commit("showLoadingModal");
      this.GetRequestWithAuthorization("api/News/MarkActiveUnActive", [
        {
          Key: "newsId",
          Value: newsId,
        },
        {
          Key: "active",
          Value: activeunactive,
        },
      ])
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Status Changed Successfully",
              type: "success",
            });
            self.dialog = false;
            self.GetCategoriesAndNews();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          console.log(ex);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
