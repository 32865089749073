<template>
  <v-row
    class="dashboardview ma-0 mb-0 base p-relative homeview min-height-100"
  >
    <v-col>
      <v-row class="mt-md-15 departmentfocusbg white pt--md-5 pb--md-3">
        <v-container @click="previewDepartment(departmentinfocus)">
          <v-row class="align-center">
            <v-col cols="12">
              <v-img
                :src="departmentinfocus.images[0].url"
                class="white--text align-end rounded"
                gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.8)"
                height="400px"
              >
                <v-chip color="white" small class="ml-3 mb-2" outlined>
                  Department In Focus
                </v-chip>
                <p class="white--text xlgFont fw-700 px-4 mb-2">
                  {{ departmentinfocus.title }}
                </p>
                <p class="white--text smFont px-4 pb-2">
                  {{ departmentinfocus.department.title }}
                  <span class="px-3">|</span>Dated:
                  {{ departmentinfocus.publishedOn }}
                </p>
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <v-row class="base py-md-5">
        <v-container>
          <v-row class="align-center justify-center my-0">
            <v-col class="py-1 justify-center">
              <v-chip-group
                class="departtabs"
                mandatory
                show-arrows
                v-model="filteredDepartment"
              >
                <v-chip color="accentdark" label outlined> All </v-chip>
                <v-chip
                  v-for="dept in departments"
                  :key="dept.id"
                  color="accentdark"
                  label
                  outlined
                >
                  {{ dept.title }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>

          <v-row class="mb-3">
            <v-col
              v-if="departmentpostsunfocused[0]"
              cols="12"
              md="4"
              class="pr-md-1"
              @click="previewDepartment(departmentpostsunfocused[0])"
            >
              <v-img
                :src="departmentpostsunfocused[0].images[0].url"
                class="white--text align-end rounded"
                gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.8)"
                height="375px"
              >
                <p class="white--text lgFont fw-700 px-4 mb-0 text-truncate">
                  {{ departmentpostsunfocused[0].title }}
                </p>
                <p class="white--text xsFont px-4 pb-1">
                  {{ departmentpostsunfocused[0].department.title }}

                  <span class="px-3">|</span>Dated:
                  {{ departmentpostsunfocused[0].publishedOn }}
                </p>
              </v-img>
            </v-col>
            <v-col cols="12" md="8" class="pl-md-1">
              <v-row>
                <v-col
                  cols="6"
                  class="pr-md-1 pb-1"
                  v-for="deptpost in departmentpostsunfocused.slice(1, 5)"
                  :key="deptpost.id"
                >
                  <v-img
                    :src="deptpost.images[0].url"
                    class="white--text align-end rounded"
                    gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.8)"
                    height="180px"
                  >
                    <p
                      class="white--text lgFont fw-700 px-4 mb-0 text-truncate"
                    >
                      {{ deptpost.title }}
                    </p>
                    <p class="white--text xsFont px-4 pb-1">
                      {{ deptpost.department.title }}
                      <span class="px-3">|</span>Dated:
                      {{ deptpost.publishedOn }}
                    </p>
                  </v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col
              cols="12"
              md="6"
              class="py-0"
              v-for="deptpost in departmentpostsunfocused.slice(
                5,
                departmentpostsunfocused.length
              )"
              :key="deptpost.id"
              @click="previewDepartment(deptpost)"
            >
              <v-row class="borderBottom-1 border-secondary my-2 mx-0 xsFont">
                <v-col class="pl-0 min-wd-0">
                  <v-row class="align-center">
                    <v-col class="accentdark--text mdFont text-truncate fw-500">
                      {{ deptpost.title }}
                    </v-col>
                  </v-row>

                  <v-row class="mt-1 align-center">
                    <v-col class="accentdark--text pt-0 xxsFont">
                      {{ deptpost.department.title }}

                      <span class="px-4">|</span>Dated:
                      {{ deptpost.publishedOn }}
                    </v-col>
                  </v-row>

                  <v-row class="mt-2 align-center">
                    <v-col class="accentdark--text pt-0 xxsFont">
                      <div
                        class="departtexthome p-relative"
                        v-html="deptpost.description"
                      ></div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="auto">
                  <img
                    :src="deptpost.images[0].url"
                    class="starclubmini rounded"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <v-row class="white py-md-5 pb-md-8">
        <v-container>
          <company-policy-component :isEditable="false" />
        </v-container>
      </v-row>

      <div class="footerclear"></div>
    </v-col>
    <v-dialog v-model="previewDeptDialog" max-width="750" scrollable>
      <v-card class="mb-0">
        <vue-scroll>
          <v-card-text
            class="pa-0 accentdark--text"
            v-if="deptPostModel.images.length > 0"
          >
            <v-row class="mx-0">
              <v-col class="px-0">
                <img :src="deptPostModel.images[0].url" style="width: 100%" />
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-3">
              <v-col cols="12" class="pt-0">
                <v-row no-gutters v-if="deptPostModel.isInFocus">
                  <v-col cols="12">
                    <v-chip outlined color="accent" small
                      >Department in Focus</v-chip
                    >
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-3">
                  <v-col cols="12">
                    <h2>{{ deptPostModel.title }}</h2>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="mt-3">
                    <p>
                      {{ deptPostModel.department.title }} | Dated:
                      {{ deptPostModel.publishedOn }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="xsFont"
                    v-html="deptPostModel.description"
                  >
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" md="8"  class="mx-auto">
                    <v-slide-group
                      v-model="model"
                      class="px-0 py-1 newsslider newsmodalitems"
                      show-arrows
                    >
                      <v-slide-item
                        class="px-2"
                        v-for="(item, i) in deptPostModel.images"
                        :key="i"
                      >
                        <div class="ceomodalitems">
                          <iframe
                            v-if="item.assetType == 'drive'"
                            :src="item.url"
                            width="200px"
                            height="180px"
                          ></iframe>
                          <iframe
                            v-if="item.assetType == 'youtube'"
                            width="200px"
                            height="180px"
                            :src="item.url"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>

                          <video-player
                            v-if="item.assetType == 'video'"
                            class="video-player-box"
                            ref="videoPlayerPopup"
                            :options="item.playerPopupOptions"
                          />
                          <img
                            v-if="item.assetType == 'Image'"
                            :src="item.url"
                          />
                        </div>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>

    <Footer v-if="$route.path != '/' && !$route.path.includes('admin')" />
  </v-row>
</template>

<script>
import apimethods from "../mixins/apimethods";
import CompanyPolicyComponent from "../components/CompanyPolicyComponent.vue";
import Footer from "../components/Footer.vue";
export default {
  mixins: [apimethods],

  name: "DepartmentInFocusPage",
  data() {
    return {
      filteredDepartment: 0,
      departments: [],
      previewDeptDialog: false,
      deptPostModel: { images: [], VideoLink: "" },
      departmentposts: [],
    };
  },
  computed: {
    departmentinfocus() {
      if (!this.departmentposts || this.departmentposts.length == 0)
        return null;
      return this.departmentposts.find((x) => x.isInFocus == true);
    },

    departmentpostsunfocused() {
      if (!this.departmentposts || this.departmentposts.length == 0) return [];

      if (this.filteredDepartment != 0) {
        return this.departmentposts.filter(
          (x) =>
            x.isInFocus == false &&
            x.departmentId == this.departments[this.filteredDepartment - 1].id
        );
      }

      return this.departmentposts.filter((x) => x.isInFocus == false);
    },
  },
  components: { Footer, CompanyPolicyComponent },
  mounted() {
    this.GetDepartmentData();
  },
  methods: {
    previewDepartment(dept) {
      dept.images.forEach((element) => {
        if (element.assetType == "video") {
          element.playerOptions = {
            // videojs options
            muted: false,
            language: "en",
            width: "335px",
            height: "200px",
            sources: [
              {
                type: "video/mp4",
                src: element.url,
              },
            ],
          };
          element.playerPopupOptions = {
            // videojs options
            muted: false,
            language: "en",
            width: "250px",
            height: "180px",
            sources: [
              {
                type: "video/mp4",
                src: element.url,
              },
            ],
          };
        }
      });
      this.deptPostModel = dept;
      this.previewDeptDialog = true;
    },
    GetDepartmentData() {
      this.filteredDepartment = 0;
      var self = this;
      this.$store.commit("showLoadingModal");
      var getdepartments = this.GetRequestWithAuthorization(
        "api/Department/Get",
        undefined
      )
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.departments = res.data.data;
          }
        })
        .catch((ex) => {});

      var getdepartmentposts = this.GetRequestWithAuthorization(
        "api/Department/GetPosts",
        undefined
      )
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.departmentposts = res.data.data;
            self.departmentposts.forEach((element) => {
              element.images.forEach((image) => {
                if (image.assetType == "Image") {
                  image.url = image.url + "?" + performance.now();
                }
              });
            });
          }
        })
        .catch((ex) => {});

      Promise.all([getdepartments, getdepartmentposts]).then((values) => {
        this.$store.commit("hideLoadingModal");
      });
    },
  },
};
</script>