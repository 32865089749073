<template>
  <v-row no-gutters>
    <v-col cols="12" class="departmentinfocus">
      <v-card>
        <v-card-title class="borderBottom-1 border-accentxlight"
          >Department In Focus</v-card-title
        >
        <v-card-text class="pa-0" v-if="deparmentinFocus">
          <v-img
            v-if="deparmentinFocus.images"
            :src="deparmentinFocus.images[0].url"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.8)"
            height="300px"
          >
            <p
              class="white--text lgFont fw-700 px-3 mb-2"
              v-if="deparmentinFocus.department"
            >
              {{ deparmentinFocus.department.title }}
            </p>

            <v-row class="p-relative align-center mx-0 my-3">
              <v-col
                class="py-0 departhome pr-0 white--text xsFont text-truncate"
              >
                <div class="ceomessage seemorediv departfocus">
                  <div v-html="deparmentinFocus.description"></div>
                  <a
                    @click.stop="previewDeptDialog = true"
                    class="readmoredot d-none"
                    >Read More...</a
                  >
                </div>
              </v-col>
            </v-row>
          </v-img>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog
      v-model="previewDeptDialog"
      max-width="750"
      content-class="previewDeptDialog"
      scrollable
    >
      <v-card class="mb-0">
        <vue-scroll>
          <v-card-text class="pa-0 accentdark--text">
            <v-row class="mx-0">
              <v-col class="px-0" v-if="deparmentinFocus.images">
                <img
                  :src="deparmentinFocus.images[0].url"
                  style="width: 100%"
                  class="headerimg"
                />
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-3">
              <v-col cols="12" class="pt-0">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-chip outlined color="accent" small
                      >Department in Focus</v-chip
                    >
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-3">
                  <v-col cols="12">
                    <h2>{{ deparmentinFocus.title }}</h2>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="mt-3"
                    v-if="deparmentinFocus.department"
                  >
                    <p>
                      {{ deparmentinFocus.department.title }} | Dated:
                      {{ deparmentinFocus.publishedOn }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="xsFont"
                    v-html="deparmentinFocus.description"
                  >
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" md="8" class="mx-auto">
                    <v-slide-group
                      v-model="model"
                      class="px-0 py-1 newsslider newsmodalitems"
                      show-arrows
                    >
                      <v-slide-item
                        class="px-2"
                        v-for="(item, i) in deparmentinFocus.images"
                        :key="i"
                      >
                        <div class="ceomodalitems">
                          <iframe
                            v-if="item.assetType == 'drive'"
                            :src="item.url"
                            width="200px"
                            height="180px"
                          ></iframe>
                          <iframe
                            v-if="item.assetType == 'youtube'"
                            width="200px"
                            height="180px"
                            :src="item.url"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>

                          <video-player
                            v-if="item.assetType == 'video'"
                            class="video-player-box"
                            ref="videoPlayerPopup"
                            :options="item.playerPopupOptions"
                          />
                          <img
                            v-if="item.assetType == 'Image'"
                            :src="item.url"
                          />
                        </div>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>
  </v-row>
</template>

   <script>
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";

export default {
  mixins: [apimethods, helpermethods],

  data() {
    return {
      previewDeptDialog: false,
      deparmentinFocus: {},
    };
  },
  name: "DepartmentInFocus",
  mounted() {
    this.GetDepartmentInFocus();
  },
  methods: {
    GetDepartmentInFocus() {
      this.$store.commit("showLoadingModal");
      var self = this;
      self.listofNews = [];

      this.GetRequestWithAuthorization(
        "api/Department/GetPostInFocus",
        undefined
      )
        .then((res) => {
          this.$store.commit("hideLoadingModal");

          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.deparmentinFocus = JSON.parse(JSON.stringify(res.data.data));
            if (self.deparmentinFocus) {
              self.deparmentinFocus.images.forEach((element) => {
                if (element.assetType == "Image") {
                  element.url = element.url + "?" + performance.now();
                }
                if (element.assetType == "video") {
                  element.playerOptions = {
                    // videojs options
                    muted: false,
                    language: "en",
                    width: "335px",
                    height: "200px",
                    sources: [
                      {
                        type: "video/mp4",
                        src: element.url,
                      },
                    ],
                  };
                  element.playerPopupOptions = {
                    // videojs options
                    muted: false,
                    language: "en",
                    width: "250px",
                    height: "180px",
                    sources: [
                      {
                        type: "video/mp4",
                        src: element.url,
                      },
                    ],
                  };
                }
              });
            }
            setTimeout(() => {
              self.allocateseemorediv(null);
            }, 10);
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>