<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute">
          <v-row class="align-center">
            <v-col class="lgFont accentdark--text fw-700">
              Star Club Program- Quarters List</v-col
            >
            <v-col cols="auto">
              <v-btn class="accent white--text" @click="ShowQuarterPopup('Add')"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                >Add Quarter</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="fill-height p-relative mt-0 mb-2">
            <v-col cols="pt-0">
              <v-row class="fill-height p-absolute wd-100 mt-0">
                <vue-scroll>
                  <v-col cols="12" class="pt-0">
                    <v-row
                      class="
                        starcluballborder
                        borderBottom-1
                        borderTop-1
                        border-secondary
                        my-3
                        mx-0
                        xsFont
                      "
                    >
                      <v-col cols="6" sm="auto">
                        <img
                          :src="starclubmaindesc.imageURL"
                          class="starclubmini rounded"
                        />
                      </v-col>

                      <v-col
                        cols="6"
                        sm="auto"
                        class="text-right pr-0 d-sm-none"
                      >
                        <v-btn
                          text
                          color="accentlight"
                          @click.stop="loadEditMainDescPopup"
                          class="editbtn mr-1"
                        >
                          <span class="material-icons-outlined lgFont mr-1"
                            >edit</span
                          >Edit</v-btn
                        >
                      </v-col>

                      <v-col cols="12" class="pl-sm-0 pt-0 pt-sm-3 col-sm">
                        <v-row class="align-center">
                          <v-col class="accentdark--text lgFont fw-500">{{
                            starclubmaindesc.title
                          }}</v-col>
                          <v-col
                            cols="auto"
                            class="text-right pr-0 d-none d-sm-block"
                          >
                            <v-btn
                              text
                              color="accentlight"
                              @click.stop="loadEditMainDescPopup"
                              class="editbtn mr-1"
                            >
                              <span class="material-icons-outlined lgFont mr-1"
                                >edit</span
                              >Edit</v-btn
                            >
                          </v-col>
                        </v-row>

                        <v-row class="mt-2 align-center">
                          <v-col
                            cols="12"
                            class="
                              accentdark--text
                              pt-0
                              xxsFont
                              fw-400
                              p-relative
                            "
                          >
                            <div class="ceomessage ourcompanyabt seemorediv">
                              <div v-html="starclubmaindesc.description"></div>
                              <a
                                @click="
                                  ShowDiscriptionDialog(
                                    starclubmaindesc.title,
                                    starclubmaindesc.description
                                  )
                                "
                                class="readmoredot d-none"
                                >... See More</a
                              >
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="align-center mb-1">
                      <v-col
                        cols="auto"
                        class="accent--text pb-0 fw-500 mdFont1"
                        >Active Post</v-col
                      >
                    </v-row>
                    <v-row
                      v-for="item in starclubQuarters.slice(0, 1)"
                      :key="item.id"
                      no-gutters
                      class="border-secondary border-1 rounded mt-3"
                    >
                      <v-col>
                        <v-row
                          no-gutters
                          class="ma-0 mx-3 my-2 px-1 align-center"
                        >
                          <v-col class="accentlight--text fw-500 mdFont1">{{
                            item.quarterTypeLong
                          }}</v-col>
                          <v-col cols="auto" class="text-right pr-0">
                            <v-btn
                              text
                              color="accentlight"
                              class="editbtn"
                              @click="
                                ShowQuarterPopup(
                                  'Edit',
                                  JSON.parse(JSON.stringify(item))
                                )
                              "
                            >
                              <span class="material-icons-outlined lgFont mr-1"
                                >edit</span
                              >Edit</v-btn
                            >
                          </v-col>
                        </v-row>

                        <v-row no-gutters class="mx-0 mb-4 mt-2 px-1">
                          <v-col
                            cols="12"
                            class="pb-2 pb-md-0"
                            sm="6"
                            md="3"
                            v-for="acheiver in item.acheivers"
                            :key="acheiver.id"
                          >
                            <v-row
                              no-gutters
                              class="
                                mx-3
                                pa-1
                                my-0
                                starachiever
                                border-secondary border-1
                                rounded
                              "
                            >
                              <v-col cols="auto" class="line-height-0">
                                <img
                                  v-if="acheiver.acheiver.profileImageURL"
                                  :src="acheiver.acheiver.profileImageURL"
                                />
                              </v-col>
                              <v-col class="pl-3">
                                <p class="fw-500 mdFont mb-0">
                                  {{ acheiver.acheiver.firstName }}
                                  {{ acheiver.acheiver.lastName }}
                                </p>
                                <p class="xsFont mb-0">
                                  {{ acheiver.acheiver.department }}
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="align-center">
                      <v-col
                        cols="auto"
                        class="accentlight--text fw-500 mdFont1"
                        >Inactive Post</v-col
                      >
                    </v-row>
                    <v-row
                      v-for="item in starclubQuarters.slice(
                        1,
                        starclubQuarters.length
                      )"
                      :key="item.id"
                      no-gutters
                      class="border-secondary border-1 rounded mt-3"
                    >
                      <v-col>
                        <v-row
                          no-gutters
                          class="ma-0 mx-3 my-2 px-1 align-center"
                        >
                          <v-col class="accentlight--text fw-500 mdFont1">{{
                            item.quarterTypeLong
                          }}</v-col>
                          <v-col cols="auto" class="text-right pr-0">
                            <v-btn
                              text
                              color="accentlight"
                              class="editbtn"
                              @click="
                                ShowQuarterPopup(
                                  'Edit',
                                  JSON.parse(JSON.stringify(item))
                                )
                              "
                            >
                              <span class="material-icons-outlined lgFont mr-1"
                                >edit</span
                              >Edit</v-btn
                            >
                          </v-col>
                        </v-row>

                        <v-row no-gutters class="mx-0 mb-4 mt-2 px-1">
                          <v-col
                            cols="12"
                            class="pb-2 pb-md-0"
                            sm="6"
                            md="3"
                            v-for="acheiver in item.acheivers"
                            :key="acheiver.id"
                          >
                            <v-row
                              no-gutters
                              class="
                                mx-3
                                pa-1
                                my-0
                                starachiever
                                border-secondary border-1
                                rounded
                              "
                            >
                              <v-col cols="auto" class="line-height-0">
                                <img
                                  v-if="acheiver.acheiver.profileImageURL"
                                  :src="acheiver.acheiver.profileImageURL"
                                />
                              </v-col>
                              <v-col class="pl-3">
                                <p class="fw-500 mdFont mb-0">
                                  {{ acheiver.acheiver.firstName }}
                                  {{ acheiver.acheiver.lastName }}
                                </p>
                                <p class="xsFont mb-0">
                                  {{ acheiver.acheiver.department }}
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </vue-scroll>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog v-model="editmaindescpopup" scrollable max-width="700px">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">Star Club Program Page Edit</v-col>
            <v-col cols="auto" class="d-none d-md-block">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="editmaindescpopup = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="saveMainDesc"
                :disabled="
                  !editmaindescformvalid ||
                  !this.tempstarclubmaindesc.description ||
                  this.tempstarclubmaindesc.description.trim() == '' ||
                  !this.tempstarclubmaindesc.imageURL ||
                  this.tempstarclubmaindesc.imageURL.trim() == ''
                "
                :loading="savingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll>
          <v-form v-model="editmaindescformvalid" ref="editmaindescform">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Heading"
                    outlined
                    dense
                    v-model="tempstarclubmaindesc.title"
                    :rules="[(v) => !!v.trim() || 'Title is required']"
                    maxlength="50"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <div class="document-editor__toolbar"></div>
                  <div class="document-editor__editable-container">
                    <ckeditor
                      style="height: 180px !important; background-color: white"
                      :editor="editor"
                      :config="editorConfig"
                      :rules="[(v) => !!v.trim() || 'Description is required']"
                      v-model="tempstarclubmaindesc.description"
                      @ready="onReady"
                    ></ckeditor>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-tabs show-arrows dense class="browsetab" height="40px">
                    <v-tab active-class="base notransform accentdark--text"
                      >Upload a Photo</v-tab
                    >

                    <v-tab-item class="pt-3 pl-0 pr-1">
                      <v-row>
                        <v-col cols="auto">
                          <div
                            class="imguploaderdiv uploadmultiimg"
                            @click="onButtonClick"
                          >
                            <span
                              class="
                                material-icons-outlined
                                accentlight--text
                                uploadicon
                              "
                            >
                              upload_file
                            </span>
                            <p class="accentdark--text uploadtext mb-0">
                              Upload a Photo
                            </p>
                          </div>
                          <!-- <v-btn
                            outlined
                            class="accentlight--text text-notransform px-8 py-4"
                            @click="onButtonClick"
                            >Browse</v-btn
                          > -->
                          <input
                            ref="fileuploadInput"
                            class="d-none"
                            type="file"
                            name="img"
                            multiple
                            accept="image/x-png,image/jpeg"
                            @change="uploadImage($event)"
                          />
                        </v-col>

                        <v-col class="pl-0">
                          <img
                            class="imgpreview"
                            :src="tempstarclubmaindesc.imageURL"
                          />
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </vue-scroll>
        <v-card-actions class="d-md-none borderTop-1 border-accentxlight mb-3">
          <v-row class="align-center text-right justify-end">
            <v-col cols="auto" class="text-right justify-end pb-0">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="editmaindescpopup = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="saveMainDesc"
                :disabled="
                  !editmaindescformvalid ||
                  !this.tempstarclubmaindesc.description ||
                  this.tempstarclubmaindesc.description.trim() == '' ||
                  !this.tempstarclubmaindesc.imageURL ||
                  this.tempstarclubmaindesc.imageURL.trim() == ''
                "
                :loading="savingData"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="starClubQuarterPopup" scrollable max-width="700px">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text"
              >{{ starclubpopuptype }} a Quarter</v-col
            >
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="starClubQuarterPopup = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text px-6"
                small
                :disabled="
                  !starclubformValid ||
                  !starclubpopupmodel.acheivers ||
                  starclubpopupmodel.acheivers.length == 0
                "
                :loading="savingData"
                @click="savestarclubquarter"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll>
          <v-card-text>
            <v-form ref="starclubform" v-model="starclubformValid">
              <v-row v-if="starclubpopuptype == 'Add'">
                <v-col cols="6" md="3">
                  <v-select
                    label="Year"
                    outlined
                    dense
                    v-model="starclubpopupmodel.year"
                    :disabled="starclubpopuptype == 'Edit'"
                    :items="dropdowndata"
                    item-text="year"
                    item-value="year"
                    :rules="[(v) => !!v || 'Year is required']"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="6" md="4">
                  <v-select
                    label="Quarter"
                    outlined
                    dense
                    v-model="starclubpopupmodel.quarterTypeId"
                    :items="yearquarters"
                    :disabled="starclubpopuptype == 'Edit'"
                    item-text="Value"
                    item-value="Key"
                    :rules="[(v) => !!v || 'Quarter is required']"
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" class="fw-700 accentDark--text">
                  {{ starclubpopupmodel.quarterTypeLong }}
                </v-col>
              </v-row>
            </v-form>
            <v-row class="align-center">
              <v-col cols="12" md="7">
                <v-autocomplete
                  ref="searchemployee"
                  v-model="selectedemployees"
                  :items="listofemployees"
                  outlined
                  dense
                  hide-details
                  label="Search by Firstname"
                  item-text="firstName"
                  :disabled="
                    starclubpopupmodel.acheivers &&
                    starclubpopupmodel.acheivers.length == 4
                  "
                  return-object
                  multiple
                >
                  <template v-slot:item="data">
                    <v-row class="py-2">
                      <v-col>
                        <p class="mb-0 mdFont accentdark--text fw-500">
                          {{ data.item.firstName }} {{ data.item.lastName }}
                        </p>
                        <p class="mb-0 xsFont accentlight--text">
                          {{ data.item.department }}
                        </p>
                      </v-col>
                    </v-row>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="5"
                v-if="
                  starclubpopupmodel.acheivers &&
                  starclubpopupmodel.acheivers.length == 4
                "
                class="error--text py-0 py-sm-3"
              >
                *Can add upto 4 acheivers only.
              </v-col>
            </v-row>

            <v-row v-for="item in starclubpopupmodel.acheivers" :key="item.id">
              <v-col cols="12" class="employeedirectory" v-if="item.acheiver">
                <v-card outlined class="pa-2 mb-2">
                  <v-row class="accentlight--text xsFont align-center">
                    <v-col
                      cols="3"
                      sm="auto"
                      class="line-height-0 pb-0 pb-md-3"
                    >
                      <img
                        v-if="item.acheiver.profileImageURL"
                        :src="item.acheiver.profileImageURL"
                        class="employeepic"
                      />
                    </v-col>
                    <v-col cols="9" class="text-right d-sm-none pb-0 pb-md-3">
                      <v-btn
                        color="accentlight"
                        class="removebtn pr-1"
                        text
                        @click="removeAcheiver(item)"
                      >
                        <span class="material-icons mdFont mr-1">delete</span>
                        Remove</v-btn
                      >
                    </v-col>
                    <v-col cols="4" sm="3" class="col-md min-wd-0">
                      Employee
                      <div class="accentdark--text text-truncate fw-500">
                        {{ item.acheiver.firstName }}
                        {{ item.acheiver.lastName }}
                      </div>
                    </v-col>

                    <v-col cols="4" sm="2" class="pl-md-0">
                      Department
                      <div class="accentdark--text text-truncate fw-500">
                        {{ item.acheiver.department }}
                      </div>
                    </v-col>

                    <v-col cols="auto" class="pl-md-0 d-none d-sm-block">
                      <v-btn
                        color="accentlight"
                        class="removebtn"
                        text
                        @click="removeAcheiver(item)"
                      >
                        <span class="material-icons mdFont mr-1">delete</span>
                        Remove</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight mb-3">
          <v-row class="align-center text-right justify-end">
            <v-col cols="auto" class="text-right justify-end pb-0">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="starClubQuarterPopup = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text px-6"
                small
                :disabled="
                  !starclubformValid ||
                  !starclubpopupmodel.acheivers ||
                  starclubpopupmodel.acheivers.length == 0
                "
                :loading="savingData"
                @click="savestarclubquarter"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDetailDialog" scrollable max-width="700px">
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col class="accentdark--text">{{ DetailDialogTitle }}</v-col>
            <v-col cols="auto" class="d-none d-md-block">
              <v-btn
                plain
                class="secondarydark--text px-0"
                x-small
                @click="showDetailDialog = false"
              >
                <span class="material-icons-outlined"> close </span></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div v-html="DetailDialogDescription"></div>
              </v-col>
            </v-row>
          </v-card-text>
        </vue-scroll>
        <v-card-actions class="borderTop-1 border-accentxlight d-md-none">
          <v-row class="align-center text-right justify-end">
            <v-col cols="auto" class="text-right justify-end">
              <v-btn
                plain
                class="secondarydark--text px-0"
                x-small
                @click="showDetailDialog = false"
              >
                <span class="material-icons-outlined"> close </span></v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";

export default {
  mixins: [apimethods, helpermethods],

  components: { ckeditor: CKEditor.component },
  data() {
    return {
      showDetailDialog: false,
      DetailDialogTitle: "",
      DetailDialogDescription: "",
      selectedemployees: [],
      editmaindescpopup: false,
      savingData: false,
      starClubQuarterPopup: false,
      starclubpopuptype: "Add",
      editmaindescformvalid: false,
      starclubformValid: false,
      starclubpopupmodel: {},
      starclubmaindesc: {},
      tempstarclubmaindesc: {},
      listofemployees: [],
      starclubQuarters: [],
      dropdowndata: [],
      filteredEmployees: [],
      editor: DocumentEditor,
      editorConfig: {
        toolbar: {
          items: [
            "bold",
            "italic",
            "underline",
            "numberedList",
            "indent",
            "outdent",
          ],
          shouldNotGroupWhenFull: true,
        },
      },
    };
  },
  computed: {
    yearquarters() {
      var selYear = this.dropdowndata.find(
        (x) => x.year == this.starclubpopupmodel.year
      );
      if (selYear) return selYear.quarters;

      return [];
    },
  },
  name: "StarClubPage",
  props: {
    msg: String,
  },
  watch: {
    selectedemployees(newValue, oldValue) {
      this.starclubpopupmodel.acheivers = [];
      if (newValue == null || newValue == undefined || newValue.length == 0)
        return;
      newValue.forEach((element) => {
        this.addAcheiver(element);
      });
    },
  },
  mounted() {
    this.GetStarClubData();
  },
  methods: {
    ShowDiscriptionDialog(title, description) {
      this.DetailDialogDescription = description;
      this.showDetailDialog = true;
      this.DetailDialogTitle = title;
    },
    addAcheiver(acheiver) {
      this.starclubpopupmodel.acheivers.push({
        acheiverId: acheiver.id,
        acheiver: acheiver,
      });

      if (this.starclubpopupmodel.acheivers.length == 4) {
        this.$refs.searchemployee.blur();
      }
    },
    removeAcheiver(acheiver) {
      this.starclubpopupmodel.acheivers =
        this.starclubpopupmodel.acheivers.filter((x) => x != acheiver);
      this.selectedemployees = JSON.parse(
        JSON.stringify(this.starclubpopupmodel.acheivers.map((x) => x.acheiver))
      );
    },
    ShowQuarterPopup(popupType, model = { acheivers: [] }) {
      this.starclubpopuptype = popupType;
      this.selectedemployees = model.acheivers.map((x) => x.acheiver);
      this.starclubpopupmodel = model;
      this.starClubQuarterPopup = true;
      this.$refs.starclubform.resetValidation();
    },
    onButtonClick() {
      this.$refs.fileuploadInput.click();
    },
    uploadImage(event) {
      if (event.target.files != null && event.target.files.length > 0) {
        var arrayFile = Array.from(event.target.files);
        arrayFile = arrayFile.filter((x) => this.isFileImage(x));

        if (arrayFile.length > 1) {
          this.$notify({
            group: "notification",
            title: "Error",
            text: "You can select only a single Image",
            type: "error",
          });
          this.$refs.fileuploadInput.value = "";
          return;
        }

        this.tempstarclubmaindesc.imageURL = URL.createObjectURL(arrayFile[0]);
        this.tempstarclubmaindesc.filecontent = arrayFile[0];
      }
    },
    onReady(editor) {
      setTimeout(() => {
        const toolbarContainer = document.querySelector(
          ".document-editor__toolbar"
        );
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
        var pictureUploadbtn = toolbarContainer.getElementsByClassName(
          "ck-file-dialog-button"
        );
        if (pictureUploadbtn != undefined && pictureUploadbtn.length > 0)
          pictureUploadbtn[0].classList.add("d-none");
      }, 200);
    },
    loadEditMainDescPopup() {
      this.tempstarclubmaindesc = JSON.parse(
        JSON.stringify(this.starclubmaindesc)
      );
      this.editmaindescpopup = true;
      this.$refs.editmaindescform.resetValidation();
    },
    saveMainDesc() {
      this.$store.commit("showLoadingModal");
      this.savingData = true;
      var self = this;

      var bodyFormData = new FormData();
      bodyFormData.append("id", this.tempstarclubmaindesc.id);
      bodyFormData.append("title", this.tempstarclubmaindesc.title);
      bodyFormData.append("description", this.tempstarclubmaindesc.description);
      if (
        this.tempstarclubmaindesc.filecontent != null &&
        this.tempstarclubmaindesc.filecontent != undefined &&
        this.tempstarclubmaindesc.filecontent
      ) {
        bodyFormData.append("file", this.tempstarclubmaindesc.filecontent);
      }

      var url = "api/StarClub/EditMainDescription";

      this.PostFormRequestWithAuthorization(url, undefined, bodyFormData)
        .then((res) => {
          self.$store.commit("hideLoadingModal");
          self.savingData = false;
          if (res != undefined && res.data != undefined) {
            if (res.data.data == true) {
              self.$notify({
                group: "notification",
                title: "Success",
                text: "Main Description Saved Successfully",
                type: "success",
              });
              self.GetStarClubData();
              self.editmaindescpopup = false;
            } else {
              self.$notify({
                group: "notification",
                title: "Error",
                text: res.data.errorMessage,
                type: "error",
              });
            }
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.savingData = false;
          console.log(ex);
        });
    },
    GetStarClubData() {
      var self = this;
      this.$store.commit("showLoadingModal");
      var getqrtranddescprom = this.GetRequestWithAuthorization(
        "api/StarClub/Get",
        undefined
      )
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.starclubmaindesc = {
              id: res.data.data.id,
              title: res.data.data.title,
              description: res.data.data.description,
              imageURL: res.data.data.imageURL + "?" + performance.now(),
            };
            self.starclubQuarters = res.data.data.starClubAcheivers;
            setTimeout(() => {
              self.allocateseemorediv(null);
            }, 10);
          }
        })
        .catch((ex) => {});

      var getdropdowndata = this.GetRequestWithAuthorization(
        "api/StarClub/GetDropDownData",
        undefined
      )
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.dropdowndata = res.data.data;
          }
        })
        .catch((ex) => {});

      var object = {};
      var elementJson = JSON.stringify(object);
      var employeeList = this.PostRequestWithAuthorization(
        "api/Employee/Get",
        undefined,
        elementJson
      )
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined
          ) {
            self.listofemployees = res.data.data;
          }
        })
        .catch((ex) => {});

      Promise.all([getqrtranddescprom, getdropdowndata, employeeList]).then(
        (values) => {
          this.$store.commit("hideLoadingModal");
        }
      );
    },
    savestarclubquarter() {
      var self = this;
      this.savingData = true;
      this.$store.commit("showLoadingModal");
      var elementJson = JSON.stringify(this.starclubpopupmodel);
      var message = "Quarter Added Successfully";
      if (this.starclubpopuptype == "Edit") {
        message = "Quarter Updated Successfully";
      }
      var url = "api/StarClub/AddStarClubQuarter";
      if (this.starclubpopuptype == "Edit")
        url = "api/StarClub/EditStarClubQuarter";
      this.PostRequestWithAuthorization(url, undefined, elementJson)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          self.savingData = false;

          if (
            res != undefined &&
            res.data != undefined &&
            res.data.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: message,
              type: "success",
            });
            self.starClubQuarterPopup = false;
            self.GetStarClubData();
          } else {
            self.$notify({
              group: "notification",
              title: "Error",
              text: res.data.errorMessage,
              type: "error",
            });
          }
        })
        .catch((ex) => {
          self.savingData = false;
          this.$store.commit("hideLoadingModal");
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
