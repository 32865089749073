<template>
  <v-row class="d-flex fill-height ma-0">
    <v-col class="px-0 pl-md-0 pr-lg-3 pt-0 pt-lg-3" cols="12">
      <v-row class="ma-0 fill-height p-relative box-shadow-1">
        <v-col class="white rounded d-flex fill-height flex-column p-absolute col-12">
          <v-row class="align-center">
            <v-col class="lgFont accentdark--text fw-700"> CEO's Posts </v-col>
            <v-col cols="auto">
              <v-btn
                class="accent white--text"
                @click.stop="OpenDialog('create')"
                ><span class="material-icons-outlined mr-1 lgFont"
                  >add_circle_outline</span
                >CREATE Post</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="fill-height p-relative mb-0">
            <v-col>
              <v-row class="fill-height p-absolute wd-100">
                <vue-scroll :ops="ops">
                  <v-col cols="12">
                    <v-row class="align-center">
                      <v-col cols="auto" class="accent--text fw-500 mdFont1"
                        >Active Post</v-col
                      >
                    </v-row>

                    <v-row
                      v-for="item in listofActiveceocorner"
                      :key="item.id"
                      class="align-center weatherslider"
                    >
                      <v-col cols="12" class="pt-0">
                        <div
                          class="rounded border-accentxlight border-1 pa-3 ma-0"
                        >
                          <v-row class="">
                            <v-col cols="7" sm="auto" class="min-wd-0">
                              <img
                                src="../../assets/ceo-corner.png"
                                class="dashboardceocornerimg"
                              />
                            </v-col>
                             <v-col cols="5" class="d-sm-none text-right pr-0 pt-2 pb-0  d-flex flex-column">
                                  <v-row
                                    no-gutters
                                    class="justify-center align-center"
                                  >
                                  
                                    <v-col cols="12" 
                                      ><v-btn
                                        text
                                        class="editbtn mobaligned"
                                        medium
                                        color="accentlight"
                                        @click="OpenDialog('edit', item)"
                                      >
                                        <span
                                          class="
                                            material-icons-outlined
                                            lgFont
                                            mr-1
                                          "
                                          >edit</span
                                        >
                                        Edit</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="12"
                                      ><v-btn
                                        text
                                        medium
                                        class="removebtn mobaligned"
                                        color="accentlight"
                                        @click="confirmRemove(item.id)"
                                      >
                                        <span class="material-icons lgFont mr-1"
                                          >delete</span
                                        >
                                        Remove</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="12" class="mobaligned">
                                      <v-radio-group   hide-details v-model="item.isActive" class="mt-0 pt-0 mobradio">
                                        <v-radio
                                          small
                                          hide-details
                                          :value="true"
                                          class="mt-0 pt-0 customcheckbox "
                                          label="ACTIVE"
                                          disabled
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                </v-col>
                            <v-col cols="12" class=" col-sm min-wd-0">
                              <v-row class="mx-0 mx-sm-n3 align-center">
                                <v-col
                                  class="
                                    min-wd-0
                                    text-truncate
                                    mdFont
                                    fw-500
                                    pl-0
                                  "
                                >
                                  {{ item.title }}</v-col
                                >
                                <v-col cols="auto d-none d-sm-block">
                                  <v-row
                                    no-gutters
                                    class="justify-center align-center"
                                  >
                                    <v-col cols="auto" class="mr-2"> </v-col>
                                    <v-col cols="auto" 
                                      ><v-btn
                                        text
                                        class="editbtn"
                                        medium
                                        color="accentlight"
                                        @click="OpenDialog('edit', item)"
                                      >
                                        <span
                                          class="
                                            material-icons-outlined
                                            lgFont
                                            mr-1
                                          "
                                          >edit</span
                                        >
                                        Edit</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="auto"
                                      ><v-btn
                                        text
                                        medium
                                        class="removebtn"
                                        color="accentlight"
                                        @click="confirmRemove(item.id)"
                                      >
                                        <span class="material-icons lgFont mr-1"
                                          >delete</span
                                        >
                                        Remove</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="auto">
                                      <v-radio-group   hide-details v-model="item.isActive" class="mt-0 pt-0">
                                        <v-radio
                                          small
                                          hide-details
                                          :value="true"
                                          class="mt-0 pt-0 customcheckbox"
                                          label="ACTIVE"
                                          disabled
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row
                                class="xsFont my-1 pr-sm-3 mx-0 mx-sm-n3 align-center"
                                v-html="item.description"
                              >
                              </v-row>

                              <v-row class="mt-0 mx-0 mx-sm-n3 align-center"> 
                                <v-col
                                  class="px-1 pt-0 ceoadminimgs"
                                  cols="auto"
                                  v-for="photoitem in item.photosLink"
                                  :key="photoitem"
                                >
                                  <img
                                    class="rounded"
                                    style="
                                      height: 80px !important;
                                      width: 120px !important;
                                    "
                                    :src="photoitem.imageLink"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row class="align-center">
                      <v-col
                        cols="auto"
                        class="accentlight--text fw-500 mdFont1"
                        >Inactive Post</v-col
                      >
                    </v-row>

                    <v-row class="mt-0 weatherslider ceoinactive">
                      <v-col cols="6" class="pr-1">
                        <v-row no-gutters>
                          <v-col
                            v-for="item in listofInActiveceocornerOdd"
                            :key="item.id"
                            cols="12"
                            class="mb-2"
                          >
                            <div
                          class="rounded border-accentxlight border-1 pa-3 ma-0"
                        >
                          <v-row class="">
                            <v-col cols="7" sm="auto" class="min-wd-0">
                              <img
                                src="../../assets/ceo-corner.png"
                                class="dashboardceocornerimg"
                              />
                            </v-col>
                             <v-col cols="5" class="d-sm-none text-right pr-0 pt-2 pb-0  d-flex flex-column">
                                  <v-row
                                    no-gutters
                                    class="justify-center align-center"
                                  >
                                  
                                    <v-col cols="12" 
                                      ><v-btn
                                        text
                                        class="editbtn mobaligned"
                                        medium
                                        color="accentlight"
                                        @click="OpenDialog('edit', item)"
                                      >
                                        <span
                                          class="
                                            material-icons-outlined
                                            lgFont
                                            mr-1
                                          "
                                          >edit</span
                                        >
                                        Edit</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="12"
                                      ><v-btn
                                        text
                                        medium
                                        class="removebtn mobaligned"
                                        color="accentlight"
                                        @click="confirmRemove(item.id)"
                                      >
                                        <span class="material-icons lgFont mr-1"
                                          >delete</span
                                        >
                                        Remove</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="12" class="mobaligned">
                                      <v-radio-group   hide-details v-model="item.isActive" class="mt-0 pt-0 mobradio">
                                        <v-radio
                                          small
                                          hide-details
                                          :value="true"
                                          class="mt-0 pt-0 customcheckbox "
                                          label="ACTIVE"
                                          disabled
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                </v-col>
                            <v-col cols="12" class=" col-sm min-wd-0">
                              <v-row class="mx-0 mx-sm-n3 align-center">
                                <v-col
                                  class="
                                    min-wd-0
                                    text-truncate
                                    mdFont
                                    fw-500
                                    pl-0
                                  "
                                >
                                  {{ item.title }}</v-col
                                >
                                <v-col cols="auto d-none d-sm-block">
                                  <v-row
                                    no-gutters
                                    class="justify-center align-center"
                                  >
                                    <v-col cols="auto" class="mr-2"> </v-col>
                                    <v-col cols="auto" 
                                      ><v-btn
                                        text
                                        class="editbtn"
                                        medium
                                        color="accentlight"
                                        @click="OpenDialog('edit', item)"
                                      >
                                        <span
                                          class="
                                            material-icons-outlined
                                            lgFont
                                            mr-1
                                          "
                                          >edit</span
                                        >
                                        Edit</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="auto"
                                      ><v-btn
                                        text
                                        medium
                                        class="removebtn"
                                        color="accentlight"
                                        @click="confirmRemove(item.id)"
                                      >
                                        <span class="material-icons lgFont mr-1"
                                          >delete</span
                                        >
                                        Remove</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="auto">
                                      <v-radio-group   hide-details v-model="item.isActive" class="mt-0 pt-0">
                                        <v-radio
                                          small
                                          hide-details
                                          :value="true"
                                          class="mt-0 pt-0 customcheckbox"
                                          label="ACTIVE"
                                          disabled
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row
                                class="xsFont my-1 pr-sm-3 mx-0 mx-sm-n3 align-center"
                                v-html="item.description"
                              >
                              </v-row>

                              <v-row class="mt-0 mx-0 mx-sm-n3 align-center"> 
                                <v-col
                                  class="px-1 pt-0 ceoadminimgs"
                                  cols="auto"
                                  v-for="photoitem in item.photosLink"
                                  :key="photoitem"
                                >
                                  <img
                                    class="rounded"
                                    style="
                                      height: 80px !important;
                                      width: 120px !important;
                                    "
                                    :src="photoitem.imageLink"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="6" class="pl-1">
                        <v-row no-gutters>
                          <v-col
                            v-for="item in listofInActiveceocornerEven"
                            :key="item.id"
                            cols="12"
                            class="mb-2"
                          >
                             <div
                          class="rounded border-accentxlight border-1 pa-3 ma-0"
                        >
                          <v-row class="">
                            <v-col cols="7" sm="auto" class="min-wd-0">
                              <img
                                src="../../assets/ceo-corner.png"
                                class="dashboardceocornerimg"
                              />
                            </v-col>
                             <v-col cols="5" class="d-sm-none text-right pr-0 pt-2 pb-0  d-flex flex-column">
                                  <v-row
                                    no-gutters
                                    class="justify-center align-center"
                                  >
                                  
                                    <v-col cols="12" 
                                      ><v-btn
                                        text
                                        class="editbtn mobaligned"
                                        medium
                                        color="accentlight"
                                        @click="OpenDialog('edit', item)"
                                      >
                                        <span
                                          class="
                                            material-icons-outlined
                                            lgFont
                                            mr-1
                                          "
                                          >edit</span
                                        >
                                        Edit</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="12"
                                      ><v-btn
                                        text
                                        medium
                                        class="removebtn mobaligned"
                                        color="accentlight"
                                        @click="confirmRemove(item.id)"
                                      >
                                        <span class="material-icons lgFont mr-1"
                                          >delete</span
                                        >
                                        Remove</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="12" class="mobaligned">
                                      <v-radio-group   hide-details v-model="item.isActive" class="mt-0 pt-0 mobradio">
                                        <v-radio
                                          small
                                          hide-details
                                          :value="true"
                                          class="mt-0 pt-0 customcheckbox "
                                          label="ACTIVE"
                                          disabled
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                </v-col>
                            <v-col cols="12" class=" col-sm min-wd-0">
                              <v-row class="mx-0 mx-sm-n3 align-center">
                                <v-col
                                  class="
                                    min-wd-0
                                    text-truncate
                                    mdFont
                                    fw-500
                                    pl-0
                                  "
                                >
                                  {{ item.title }}</v-col
                                >
                                <v-col cols="auto d-none d-sm-block">
                                  <v-row
                                    no-gutters
                                    class="justify-center align-center"
                                  >
                                    <v-col cols="auto" class="mr-2"> </v-col>
                                    <v-col cols="auto" 
                                      ><v-btn
                                        text
                                        class="editbtn"
                                        medium
                                        color="accentlight"
                                        @click="OpenDialog('edit', item)"
                                      >
                                        <span
                                          class="
                                            material-icons-outlined
                                            lgFont
                                            mr-1
                                          "
                                          >edit</span
                                        >
                                        Edit</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="auto"
                                      ><v-btn
                                        text
                                        medium
                                        class="removebtn"
                                        color="accentlight"
                                        @click="confirmRemove(item.id)"
                                      >
                                        <span class="material-icons lgFont mr-1"
                                          >delete</span
                                        >
                                        Remove</v-btn
                                      ></v-col
                                    >
                                    <v-col cols="auto">
                                      <v-radio-group   hide-details v-model="item.isActive" class="mt-0 pt-0">
                                        <v-radio
                                          small
                                          hide-details
                                          :value="true"
                                          class="mt-0 pt-0 customcheckbox"
                                          label="ACTIVE"
                                          disabled
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row
                                class="xsFont my-1 pr-sm-3 mx-0 mx-sm-n3 align-center"
                                v-html="item.description"
                              >
                              </v-row>

                              <v-row class="mt-0 mx-0 mx-sm-n3 align-center"> 
                                <v-col
                                  class="px-1 pt-0 ceoadminimgs"
                                  cols="auto"
                                  v-for="photoitem in item.photosLink"
                                  :key="photoitem"
                                >
                                  <img
                                    class="rounded"
                                    style="
                                      height: 80px !important;
                                      width: 120px !important;
                                    "
                                    :src="photoitem.imageLink"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </vue-scroll>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      v-model="dialog"
      content-class="dashboardmodal"
      scrollable
      max-width="900px"
    >
      <v-card class="mb-0">
        <v-card-title
          class="accentdark--text borderBottom-1 border-accentxlight"
        >
          <v-row class="align-center">
            <v-col>{{ PopupHeading }}</v-col>
            <v-col cols="auto" class="d-none d-sm-block">
              <v-btn
                outlined
                class="mr-2"
                style="color: #df492f"
                small
                @click="confirmRemove(Editedceocorner.id)"
                v-if="ShowDeleteButtonOnPopup"
                >Delete</v-btn
              >
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="ClosePopup()"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :loading="savingData"
                @click="SubmitForm"
                :disabled="
                  !formValid ||
                  !this.ceocornerDescription ||
                  this.ceocornerDescription.trim() == '' ||
                  !SelectedFiles ||
                  SelectedFiles.length == 0 ||
                  savingData
                "
                >{{ PopupSaveBtn }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <vue-scroll :ops="ops">
          <v-card-text>
            <v-form ref="modalForm" v-model="formValid">
              <v-row class="">
                <v-col cols="12" sm="auto" class="">
                  <img
                    src="../../assets/ceo-corner.png"
                    class="dashboardceocornerimg"
                  />
                </v-col>
                <v-col  cols="12" class="col-sm min-wd-0">
                  <v-row>
                    <v-col class="pl-sm-0 pt-sm-7 pb-sm-5">
                      <v-text-field
                        label="Title"
                        outlined
                        dense
                        hide-details
                        v-model="ceocornerTitle"
                        :rules="RequiredAndLimit"
                        maxlength="80"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row >
                    <v-col cols="12" class="pl-sm-0 pt-sm-0">
                      <div class="document-editor__toolbar"></div>
                      <div class="document-editor__editable-container">
                        <ckeditor
                          style="
                            height: 180px !important;
                            background-color: white;
                          "
                          :editor="editor"
                          v-model="ceocornerDescription"
                          :config="editorConfig"
                          @ready="onReady"
                        ></ckeditor>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pl-sm-0 pt-sm-0">
                      <v-tabs show-arrows dense class="browsetab" height="30px">
                        <v-tab active-class="base notransform accentdark--text"
                          >Upload a Photo</v-tab
                        >
                        <v-tab
                          active-class="base accentdark--text"
                          class="notransform"
                          >Link a Video</v-tab
                        >

                        <v-tab-item class="pt-3 pl-0 pr-1">
                          <v-row>
                            <v-col cols="auto" class="pr-0">
                               <div class="imguploaderdiv uploadmultiimg" @click="onButtonClick">
                                 <span class="material-icons-outlined accentlight--text uploadicon"> upload_file </span>
                                 <p class="accentdark--text uploadtext mb-0"> Upload a Photo </p>
                                </div>
                              <!-- <v-btn
                                outlined
                                class="
                                  accentlight--text
                                  text-notransform
                                  px-8
                                  py-4
                                "
                                small
                                @click="onButtonClick"
                                >Browse</v-btn
                              > -->

                              <input
                                ref="fileuploadInput"
                                class="d-none"
                                type="file"
                                name="img"
                                multiple
                                accept="image/x-png,image/jpeg"
                                @change="uploadImage($event)"
                              />
                            </v-col>
                            <v-col>
                              <img
                                v-for="item in SelectedFiles"
                                :key="item.id"
                                class="imgpreview"
                                :src="item.ImageUrl"
                              />
                            </v-col>
                          </v-row> </v-tab-item
                        ><v-tab-item class="pt-3 pl-0 pr-1">
                          <v-text-field
                            label="Video Link"
                            outlined
                            prepend-inner-icon="mdi-link"
                            dense
                            hide-details
                            v-model="ceocornerVideoLink"
                            :rules="Linkrules"
                          ></v-text-field>
                        </v-tab-item>
                      </v-tabs>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </vue-scroll>
        <v-card-actions class="d-sm-none borderTop-1 border-accentxlight">
          <v-row class="align-center mb-n1 text-right justify-end">
            <v-col cols="auto" class="text-right justify-end">
              <v-btn
                outlined
                class="mr-2"
                style="color: #df492f"
                small
                @click="confirmRemove(Editedceocorner.id)"
                v-if="ShowDeleteButtonOnPopup"
                >Delete</v-btn
              >
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="ClosePopup()"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                :loading="savingData"
                @click="SubmitForm"
                :disabled="
                  !formValid ||
                  !this.ceocornerDescription ||
                  this.ceocornerDescription.trim() == '' ||
                  !SelectedFiles ||
                  SelectedFiles.length == 0 ||
                  savingData
                "
                >{{ PopupSaveBtn }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveConfirmDialog" max-width="350">
      <v-card class="mb-0">
        <v-card-text>
          <v-row>
            <v-col class="fw-500 mdFont accentdark--text">
              Are you sure you want to remove this item?
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined
                class="accentlight--text mr-2"
                small
                @click="showRemoveConfirmDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                outlined
                class="accent--text"
                small
                @click="Removeceocorner(currentRemoveId)"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default {
  name: "CEOCornerPage",
  components: { ckeditor: CKEditor.component },
  created() {
    this.GetceocornerList();
  },
  mixins: [apimethods, helpermethods],
  data() {
    return {
      editor: DocumentEditor,
      ops: {
        scrollPanel: {
          scrollingX: false,
        },
      }, 
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        placeholder: "Description",
        toolbar: {
          items: [
            "bold",
            "italic",
            "undo",
            "redo",
            "alignment:left",
            "alignment:right",
            "alignment:center",
            "alignment:justify",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "indent",
            "outdent",
            "numberedList",
          ],
          shouldNotGroupWhenFull: true,
        },
      },
      showRemoveConfirmDialog: false,
      currentRemoveId: 0,
      formValid: false,
      dialog: false,
      listofActiveceocorner: [],
      listofInActiveceocornerOdd: [],
      listofInActiveceocornerEven: [],
      imageUrl: "",
      ceocornerTitle: "",
      ceocornerDescription: "<p></p>",
      ceocornerVideoLink: "",
      SelectedImageName: "",
      SelectedFiles: [],
      openPopupType: "",
      Editedceocorner: {},
      popupType: "",
      PopupHeading: "",
      savingData: false,
      isPhotoChanged: false,
      PopupSaveBtn: "",
      ShowDeleteButtonOnPopup: false,
      RequiredAndLimit: [
        (v) => !!v || "Item is required",
        (v) => (v && v.length <= 80) || "Value must be less than 80 characters",
      ],
      Linkrules: [
        (value) => !!value || "Required.",
        (value) => this.isVideoURL(value) || "URL is not valid",
      ],
    };
  },
  props: {
    msg: String,
  },
  methods: {
    ClosePopup() {
      this.dialog = false;
    },
    OpenDialog(type, item) {
      this.openPopupType = type;
      if (this.$refs.fileuploadInput != undefined)
        this.$refs.fileuploadInput.value = null;
      this.SelectedFiles = [];
      this.popupType = type;
      if (type == "create") {
        this.ceocornerTitle = "";
        this.ceocornerLink = "";
        this.PopupHeading = "Create a Post";
        this.ceocornerDescription = "";
        this.imageUrl = "";
        this.PopupSaveBtn = "Post";
        this.ceocornerVideoLink = "";
        this.ShowDeleteButtonOnPopup = false;
      } else {
        this.Editedceocorner = item;
        this.ceocornerTitle = item.title;
        this.ceocornerVideoLink = item.videoLink;
        this.ceocornerDescription = item.description;
        this.PopupHeading = "Edit Post";
        this.PopupSaveBtn = "Update";
        this.ShowDeleteButtonOnPopup = true;
        this.imageUrl = item.thumbnailURL;
        if (item.photosLink != undefined && item.photosLink.length > 0) {
          this.SelectedFiles = [];
          item.photosLink.forEach((element) => {
            this.SelectedFiles.push({ ImageUrl: element.imageLink });
          });
        }
      }
      this.isPhotoChanged = false;
      this.dialog = true;
    },

    SubmitForm() {
      this.savingData = true;
      if (this.popupType == "create") {
        this.Addceocorner(this.Editedceocorner);
      } else {
        this.Editceocorner(this.Editedceocorner);
      }
    },
    Editceocorner(item) {
      if (
        this.ceocornerDescription == undefined ||
        this.ceocornerDescription == ""
      ) {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Please input description",
          type: "error",
        });
        this.savingData = false;
        return;
      }
      var self = this;
      var bodyFormData = new FormData();
      bodyFormData.append("id", item.id);
      bodyFormData.append("title", this.ceocornerTitle);
      bodyFormData.append("videoLink", this.ceocornerVideoLink);
      bodyFormData.append("description", this.ceocornerDescription);
      bodyFormData.append("thumbnailURL", item.thumbnailURL);
      bodyFormData.append("UpdatePhotos", this.isPhotoChanged);
      //bodyFormData.append("thumbnailImage", item.thumbnailURL);
      if (this.SelectedFiles != undefined && this.isPhotoChanged) {
        bodyFormData.append("UpdateThumbnailImage", true);
        bodyFormData.append("name", this.SelectedImageName);
        this.SelectedFiles.forEach((element) => {
          bodyFormData.append("file", element.File);
        });
      } else {
        bodyFormData.append("UpdateThumbnailImage", false);
      }

      this.PostFormRequestWithAuthorization(
        "api/ceocorner/Edit",
        undefined,
        bodyFormData
      )
        .then((res) => {
          this.savingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "CEO's Post Updated successfully",
            });
            self.dialog = false;
            //alert("ceocorner Added");
            self.GetceocornerList();
          }
        })
        .catch((ex) => {
          console.log(ex);
          this.savingData = false;
        });
    },
    Addceocorner() {
      if (this.SelectedFiles == undefined || this.SelectedFiles.length == 0) {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Please select image",
          type: "error",
        });
        this.savingData = false;
        return;
      }
      if (
        this.ceocornerDescription == undefined ||
        this.ceocornerDescription == ""
      ) {
        this.$notify({
          group: "notification",
          title: "Error",
          text: "Please input description",
          type: "error",
        });
        this.savingData = false;
        return;
      }

      var self = this;
      var bodyFormData = new FormData();
      bodyFormData.append("title", this.ceocornerTitle);
      bodyFormData.append("videoLink", this.ceocornerVideoLink);
      bodyFormData.append("thumbnailURL", "");
      bodyFormData.append("thumbnailImage", "");
      bodyFormData.append("description", this.ceocornerDescription);
      bodyFormData.append("isActive", true);
      bodyFormData.append("UpdateThumbnailImage", true);
      bodyFormData.append("name", this.SelectedImageName);
      this.SelectedFiles.forEach((element) => {
        bodyFormData.append("file", element.File);
      });

      this.$store.commit("showLoadingModal");

      this.PostFormRequestWithAuthorization(
        "api/ceocorner/Add",
        undefined,
        bodyFormData
      )
        .then((res) => {
          this.savingData = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "CEO's Post Added Successfully",
              type: "success",
            });
            self.dialog = false;
            //alert("ceocorner Added");
            self.GetceocornerList();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");

          this.savingData = false;
          console.log(ex);
        });
    },
    ChangeActiveStatus(ceocornerId, active) {
      var self = this;
      this.$store.commit("showLoadingModal");
      this.GetRequestWithAuthorization("api/ceocorner/MarkActiveUnActive", [
        {
          Key: "ceocornerId",
          Value: ceocornerId,
        },
        {
          Key: "active",
          Value: active,
        },
      ])
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "Status Changed Successfully",
              type: "success",
            });
            self.dialog = false;
            //alert("Status Changed");
            self.GetceocornerList();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");

          console.log(ex);
        });
    },
    confirmRemove(ceocornerId) {
      this.dialog = false;
      this.$data.showRemoveConfirmDialog = true;
      this.$data.currentRemoveId = ceocornerId;
    },
    Removeceocorner(ceocornerId) {
      this.$data.showRemoveConfirmDialog = false;
      var self = this;
      this.$store.commit("showLoadingModal");

      this.GetRequestWithAuthorization("api/ceocorner/Remove", [
        {
          Key: "ceocornerId",
          Value: ceocornerId,
        },
      ])
        .then((res) => {
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            self.$notify({
              group: "notification",
              title: "Message",
              text: "CEO's Post Removed Successfully",
              type: "success",
            });
            //alert("ceocorner removed");
            self.dialog = false;
            self.GetceocornerList();
          }
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          console.log(ex);
        });
    },
    GetceocornerList() {
      try {
        var self = this;
        this.$store.commit("showLoadingModal");
        var request = this.GetRequestWithAuthorization(
          "api/ceocorner/Get",
          undefined
        );
        if (request != undefined) {
          request.then((res) => {
            this.$store.commit("hideLoadingModal");

            if (
              res != undefined &&
              res.data != undefined &&
              res.data.data != undefined
            ) {
              self.listofActiveceocorner = res.data.data.filter(
                (x) => x.isActive == true
              );
              var listofInActiveceocorner = res.data.data.filter(
                (x) => x.isActive == false
              );
              if (
                listofInActiveceocorner != undefined &&
                listofInActiveceocorner.length > 0
              ) {
                var index = 1;
                this.listofInActiveceocornerOdd = [];
                this.listofInActiveceocornerEven = [];
                listofInActiveceocorner.forEach((inactiveCeoCorner) => {
                  if (index % 2 == 0)
                    this.listofInActiveceocornerEven.push(inactiveCeoCorner);
                  else this.listofInActiveceocornerOdd.push(inactiveCeoCorner);
                  index++;
                });
              } else {
                this.listofInActiveceocornerOdd = [];
                this.listofInActiveceocornerEven = [];
              }
            }
          });
        }

        request.catch((ex) => {});
      } catch (exa) {
        this.$store.commit("hideLoadingModal");
        console.log(exa);
      }
    },
    uploadImage(event) {
      if (event.target.files != null && event.target.files.length > 0) {
        var arrayFile = Array.from(event.target.files);

        if (arrayFile.length > 5) {
          this.$notify({
            group: "notification",
            title: "Error",
            text: "You cannot select more than 5 images",
            type: "error",
          });
          return;
        }
        this.isPhotoChanged = true;
        this.SelectedFiles = [];
        arrayFile.forEach((element) => {
          var imageUrlLocal = URL.createObjectURL(element);
          this.SelectedFiles.push({ ImageUrl: imageUrlLocal, File: element });
        });
      } else {
        this.SelectedFiles = [];
      }
    },
    onButtonClick() {
      this.$refs.fileuploadInput.click();
    },
    onReady(editor) {
      setTimeout(() => {
        const toolbarContainer = document.querySelector(
          ".document-editor__toolbar"
        );
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
        var pictureUploadbtn = toolbarContainer.getElementsByClassName(
          "ck-file-dialog-button"
        );
        if (pictureUploadbtn != undefined && pictureUploadbtn.length > 0)
          pictureUploadbtn[0].classList.add("d-none");
      }, 200);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
